import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Alert,
    AlertIcon,
    Box,
    Button,
    Code,
    Image,
    Link,
    ListItem,
    OrderedList,
    UnorderedList
} from "@chakra-ui/react";
import {AxiosError} from "axios";
import * as React from "react";
import {FunctionComponent, useState} from "react";
import {useHistory} from "react-router-dom";
import {useRecoilState, useRecoilValue} from "recoil";
import {AccessKeysApi} from "../../../api/access-keys.api";
import gitHubAccessKeysImage from "../../../assets/images/getting-started/gitHubAccessKeys.png";
import {githubAccessKeysAtom, hasGitHubAccessKeyAtom} from "../../../atoms/github-access-keys.atom";
import ErrorModel from "../../../models/error.model";

interface IGenerateGithubSecretsAccordionItem {
    tokenValid: boolean;
    haveMainEntry: boolean;
}

interface IProps {
    colorPalette: any;
}

export const GenerateGithubSecretsAccordionItem: FunctionComponent<IGenerateGithubSecretsAccordionItem> = (props: IProps) => {
    const hasGitHubAccessKey = useRecoilValue(hasGitHubAccessKeyAtom);
    const [gitHubAccessKey, setGithubAccessKey] = useRecoilState(githubAccessKeysAtom);

    const [isAccessKeyLoading, setIsAccessKeyLoading] = useState<boolean>(false);

    const { accordionColor, mainFontColor, primaryButtonColor, primaryButtonColorHover, linkColor } = props.colorPalette;

    const history = useHistory();

    const handleGenerateAccessKeys = () => {
        setIsAccessKeyLoading(true);

        AccessKeysApi.generateAccessKeys()
            .then((result) => {
                setGithubAccessKey(result.data);
                setIsAccessKeyLoading(false);
            })
            .catch((error: AxiosError<ErrorModel>) => {
                //console.error(error);
                setIsAccessKeyLoading(false);
            })
    }

    const handleCopyOfAccessKeys = () => {
        navigator.clipboard.writeText(`Name: ACCESS_KEY_ID, Value = ${gitHubAccessKey.accessKeyId}\r\nName: SECRET_ACCESS_KEY, Value = ${gitHubAccessKey.secretAccessKey}`);
    }

    const handleLoginNavigation = () => {
        history.push(`/signin`);
    }

    const handleRegisterNavigation = () => {
        history.push(`/portal/entelect-challenge/register`);
    }

    return (
        <AccordionItem>
            <h2>
                <AccordionButton _expanded={{bg: accordionColor, color: 'white'}}>
                    <Box flex='1' textAlign='left'>
                        Generate and Enter Github Secrets
                    </Box>
                    <AccordionIcon/>
                </AccordionButton>
            </h2>
            <AccordionPanel pb={4} color={mainFontColor}>
                Now, you are going generate your personal access keys so, that you can automatically submit your bot to
                the Entelect Challenge servers via Github.

                <br/>

                The github secrets are necessary to ensure that your keys safe.

                <br/>

                You can also later re-generate the keys again in your <Link color={linkColor}
                                                                            href="/portal/playerProfile">profile</Link> under
                Access
                Keys

                <Alert
                    status='info'
                    variant='subtle'
                    mt={3}
                    mb={3}
                >
                    <AlertIcon/>
                    <b>NOTE:</b>
                    &nbsp;
                    Generating new keys will invalidate/remove your old keys. You will need to update your Github
                    Secrets
                </Alert>

                <OrderedList>
                    <ListItem>
                        Generate your access keys:<span>&nbsp;</span>
                        {
                            props.tokenValid
                                ? props.haveMainEntry
                                    ? <Button size={'sm'}
                                              backgroundColor={primaryButtonColor}
                                              colorScheme={'navigation'}
                                              color={'whiteAlpha.900'}
                                              isLoading={isAccessKeyLoading}
                                              _hover={{ backgroundColor: primaryButtonColorHover}}
                                              onClick={handleGenerateAccessKeys}>Generate Access Keys</Button>
                                    : <Button size={'sm'}
                                              backgroundColor={primaryButtonColor}
                                              colorScheme={'navigation'}
                                              color={'whiteAlpha.900'}
                                              _hover={{ backgroundColor: primaryButtonColorHover,
                                                  cursor: "pointer"}}
                                              onClick={handleRegisterNavigation}>Register for the Main Challenge</Button>
                                : <Button size={'sm'}
                                          backgroundColor={primaryButtonColor}
                                          colorScheme={'navigation'}
                                          color={'whiteAlpha.900'}
                                          _hover={{ backgroundColor: primaryButtonColorHover,
                                                    cursor: "pointer"}}
                                          onClick={handleLoginNavigation}>Login</Button>
                        }
                        <span>&nbsp;</span>
                        {
                            props.tokenValid && hasGitHubAccessKey &&
                            <Button size={'sm'}
                                    backgroundColor={'navigation.800'}
                                    colorScheme={'navigation'}
                                    color={'whiteAlpha.900'}
                                    onClick={handleCopyOfAccessKeys}>Copy</Button>
                        }
                    </ListItem>
                    <ListItem>
                        On Github, go to <b>Settings</b> and then <b>Secrets</b> (You are going to add 2 secrets)
                    </ListItem>
                    <ListItem>
                        Click on Create repository secret.
                    </ListItem>
                    <ListItem>
                        Enter the following:
                        <UnorderedList>
                            <ListItem>
                                Name = <Code>ACCESS_KEY_ID</Code>
                            </ListItem>
                            <ListItem>
                                Value =
                                {
                                    hasGitHubAccessKey
                                        ? <Code>{gitHubAccessKey.accessKeyId}</Code>
                                        : <Code>[Available after generated]</Code>
                                }
                            </ListItem>
                        </UnorderedList>
                    </ListItem>
                    <ListItem>
                        Click on <b>Add Secret</b>.
                    </ListItem>
                    <ListItem>
                        Click on <b>Create repository secret</b>.
                    </ListItem>
                    <ListItem>
                        Enter the following:
                        <UnorderedList>
                            <ListItem>
                                Name = <Code>SECRET_ACCESS_KEY</Code>
                            </ListItem>
                            <ListItem>
                                Value =
                                {
                                    hasGitHubAccessKey
                                        ? <Code>{gitHubAccessKey.secretAccessKey}</Code>
                                        : <Code>[Available after generated]</Code>
                                }
                            </ListItem>
                        </UnorderedList>
                    </ListItem>
                    <ListItem>
                        Click on <b>Add secret</b>.
                    </ListItem>
                </OrderedList>

                <br/>

                Your Github secrets are now setup. Next you are going to clone your repository to your computer.

                <Accordion
                    pt={3}
                    allowToggle
                >
                    <AccordionItem>
                        <h2>
                            <AccordionButton
                                _expanded={{bg: accordionColor, color: 'white'}}
                            >
                                <Box flex='1' textAlign='left'>
                                    Click here for help adding your secrets to GitHub
                                </Box>
                                <AccordionIcon/>
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <Image src={gitHubAccessKeysImage} alt='Adding your secrets to GitHub'/>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>

            </AccordionPanel>
        </AccordionItem>
    );
}
