import * as React from 'react';
import {Box, Text} from "@chakra-ui/react";
import {WikiSection} from "../WikiSection";
import {WikiSubSection} from "../WikiSubSection";

interface IWikiCompanyRulesSection {
}

export const WikiCompanyRulesSection: React.FunctionComponent<IWikiCompanyRulesSection> = _ => {

    return (
        <Box>
            <WikiSection heading={'Hackathon Rules'}>
                <WikiSubSection heading={'University Cup'}>
                    <WikiSubSection heading={'Rules'}>
                        <Text color={'black'}>
                            The University Cup is only open to full-time registered South African students. If you are working part time whilst doing your honours, you may not enter the University Cup Challenge.
                            <br/><br/>
                            The University Cup is team-based. Each team must consist of no less than two, and no more than three individuals. You need to have your team members registered before the event.
                            <br/><br/>
                            Two University Cups will be hosted throughout the year. The event will be hosted online.
                            <br/><br/>
                            The Entelect Challenge team may publish a practice problem approximately one month before the University Cup event.
                            <br/><br/>
                            Teams are only eligible to win one of the two University Cups, but can participate in both.
                            <br/><br/>
                            All submissions are done online via the Entelect Challenge player portal.
                            <br/><br/>
                            Winners will be validated.
                        </Text>
                    </WikiSubSection>
                </WikiSubSection>

                <WikiSubSection heading={'IITPSA Hackathon'}>
                    <WikiSubSection heading={'Rules'}>
                        <Text color={'black'}>
                            The IITPSA cup is open to registered IITPSA members only.
                            <br/><br/>
                            The IITPSA cup is individual based. No teams are allowed.
                            <br/><br/>
                            The IITPSA cup will take place over a single Saturday. The event will be hosted online for participating members.
                            <br/><br/>
                            All submissions are done online via the Entelect Challenge player portal.
                            <br/><br/>
                            Winners will be validated
                            <br/><br/>
                            We kindly request that participants of the IITPSA Hackathon refrain from taking part in other Entelect hackathons. This will ensure that all participants have an equal opportunity to compete for prizes and placement. Please note that players who choose to participate in multiple hackathons will unfortunately <strong>NOT</strong> be eligible for awards or prizes.
                        </Text>
                    </WikiSubSection>
                </WikiSubSection>

                <WikiSubSection heading={'Company Cup'}>
                    <WikiSubSection heading={'Rules'}>
                        <Text color={'black'}>
                            The Company Cup is open to Entelect Software (Pty) Ltd employees only.
                            <br/><br/>
                            The Company Cup is team-based. Each team must consist of no less than two, and no more than three individuals.
                            <br/><br/>
                            Individuals may compete but they will not be eligible to win.
                            <br/><br/>
                            The Company Cup will take place over a single Thursday evening. The event will be hosted online for participating employees.
                            <br/><br/>
                            All submissions are done online via the Entelect Challenge player portal.
                        </Text>
                    </WikiSubSection>
                </WikiSubSection>

            </WikiSection>
        </Box>
    )
};
