import {Icon} from "@chakra-ui/icons";
import {Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Code, Image, Link, ListItem, OrderedList} from "@chakra-ui/react";
import * as React from "react";
import {FunctionComponent} from "react";
import createRepoImage from "../../../assets/images/getting-started/createRepo.png";

interface IProps {
    colorPalette: any
}

export const CreateGithubRepositoryAccordionItem: FunctionComponent = (props: IProps) => {

    const { accordionColor, mainFontColor, linkColor, onLinkHover,
        primaryButtonColor } = props.colorPalette;
    return (
        <AccordionItem>
            <h2>
                <AccordionButton _expanded={{ bg: accordionColor, color: 'white' }}>
                    <Box flex='1' textAlign='left'>
                        Create Github Repository
                    </Box>
                    <AccordionIcon/>
                </AccordionButton>
            </h2>
            <AccordionPanel pb={4} color={mainFontColor}>
                To start, you need to create a private Github repository where you are going to push your code to, and it will automatically build and submit your bot
                to the Entelect Challenge servers.

                <br/>
                <br/>

                <OrderedList>
                    <ListItem>
                        Go to <Link
                        color={linkColor}
                        _hover={onLinkHover}
                        textDecoration={'underline'}
                        href="https://github.com" isExternal>github.com</Link>
                    </ListItem>
                    <ListItem>
                        Sign in or create an account
                    </ListItem>
                    <ListItem>
                        Click on the
                        <span>&nbsp;</span>
                        <Button
                            size={'xs'}
                            color={'white'}
                            backgroundColor={primaryButtonColor}
                            variant={'solid'}
                            leftIcon={
                                <Icon
                                    className={'octicon octicon-repo'}

                                    viewBox={'0 0 16 16'}
                                    color={'white'}>
                                    <path
                                        fill={'currentColor'}
                                        d={'M2 2.5A2.5 2.5 0 014.5 0h8.75a.75.75 0 01.75.75v12.5a.75.75 0 01-.75.75h-2.5a.75.75 0 110-1.5h1.75v-2h-8a1 1 0 00-.714 1.7.75.75 0 01-1.072 1.05A2.495 2.495 0 012 11.5v-9zm10.5-1V9h-8c-.356 0-.694.074-1 .208V2.5a1 1 0 011-1h8zM5 12.25v3.25a.25.25 0 00.4.2l1.45-1.087a.25.25 0 01.3 0L8.6 15.7a.25.25 0 00.4-.2v-3.25a.25.25 0 00-.25-.25h-3.5a.25.25 0 00-.25.25z'}
                                    />
                                </Icon>
                            }>
                            New
                        </Button>

                        <span>&nbsp;</span>button (It's green and on the left side of the screen)

                        {/* TODO: Fix this icon */}
                        {/*Click on the <NewRepositoryIcon color='green.500' /> New button (It's green and on the left side of the screen)*/}

                    </ListItem>
                    <ListItem>
                        Enter a repository name. (It can be anything you like, eg. 'ec-bot')
                    </ListItem>
                    <ListItem>
                        Choose the <b><u>Private</u></b> option
                    </ListItem>
                    <ListItem>
                        Leave everything <b><u>unchecked</u></b> (We will provide a <Code >README.md</Code> and <Code>.gitignore</Code> file)
                    </ListItem>
                    <ListItem>
                        Click on Create repository
                    </ListItem>
                </OrderedList>
                <br/>
                Your github account and repository are now created. Next you are going to generate your personal access keys.

                <Accordion
                    pt={3}
                    allowToggle
                >
                    <AccordionItem>
                        <h2>
                            <AccordionButton
                                _expanded={{ bg: accordionColor, color: 'white' }}
                            >
                                <Box flex='1' textAlign='left'>
                                    Click here for help on creating your repository
                                </Box>
                                <AccordionIcon/>
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <Image src={createRepoImage} alt='Creating your repository'/>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>

            </AccordionPanel>
        </AccordionItem>
    );
}
