import {ExpandedIndex} from "@chakra-ui/accordion/dist/types/use-accordion";
import {Text} from "@chakra-ui/layout";
import {Accordion, Box, Container, Heading, Link, useTheme} from "@chakra-ui/react";
import * as React from "react";
import {FunctionComponent} from "react";
import {useRecoilState, useRecoilValue} from "recoil";
import {gettingStartedAtom} from "../../atoms/getting-started.atom";
import {isTokenValid} from "../../atoms/token.atom";
import {CloneGithubRepositoryAccordionItem} from "./getting-started-sections/CloneGithubRepositoryAccordionItem";
import {CommitBotAccordionItem} from "./getting-started-sections/CommitBotAccordionItem";
import {CreateGithubRepositoryAccordionItem} from "./getting-started-sections/CreateGithubRepositoryAccordionItem";
import {DownloadCiCdFileAccordionItem} from "./getting-started-sections/DownloadCiCdFileAccordionItem";
import {DownloadStarterPackAccordionItem} from "./getting-started-sections/DownloadStarterPackAccordionItem";
import {GenerateGithubSecretsAccordionItem} from "./getting-started-sections/GenerateGithubSecretsAccordionItem";
import {PrerequisitesAccordionItem} from "./getting-started-sections/PrerequisitesAccordionItem";
import {playerHaveEntry} from "../../atoms/player.atom";
import {EntryType} from "../../models/entry.model";
import {getColorDefinitions, IColorDefinition} from "../../shared/styling/colorDefinitions";

interface IGettingStarted {
}

export const GettingStarted: FunctionComponent<IGettingStarted> = _ => {
    const tokenValid = useRecoilValue(isTokenValid);
    const haveMainEntry = useRecoilValue(playerHaveEntry(EntryType.MAIN));

    const [accordionIndex, setAccordionIndex] = useRecoilState(gettingStartedAtom);

    const handleAccordionItemChange = (expandedIndex: ExpandedIndex) => {
        setAccordionIndex(expandedIndex);
    }

    const theme = useTheme();
    const colorPalette: IColorDefinition = getColorDefinitions(theme)
    const {mainFontColor, titleFontColor, linkColor, onLinkHover} = colorPalette;

    const entelectChallengeForumLink = process.env.REACT_APP_ENTELECT_CHALLENGE_FORUM;

    return (
        <Box pb={'3em'}>
            <Heading
                textAlign={"center"}
                fontSize={'3xl'}
                fontWeight={'bold'}
                color={titleFontColor}
                py={7}
                colorScheme="primary">
                How to get started with the Main Challenge
            </Heading>

            <Container maxWidth={'container.lg'}>
                <Text color={mainFontColor}>
                    We have designed a new streamlined submission process that will make it easier than before to submit
                    your bot to us.
                    You will now have more control over how your bot is being built.
                    You can customize your build process and have access to your build log instantly.
                </Text>
                <br/>
                <Text color={mainFontColor}>
                    But don't worry, the default settings are more than enough to build and submit your bot, without
                    touching any settings.
                    We will walk you through the one-time process.
                </Text>
                <Text color={mainFontColor}>
                    Please don't hesitate to{' '}
                    <Link color={linkColor} _hover={onLinkHover} href="mailto:challenge@entelect.co.za">
                        email us
                    </Link>
                    {' '}or ask on the{' '}
                    <Link
                        color={linkColor}
                        _hover={onLinkHover}
                        href={entelectChallengeForumLink}
                        isExternal>
                        forum
                    </Link>
                    {' '}if you are having issues or struggle to
                    get this working and we will assist you through this process.
                </Text>
                <br/>
                <Text color={mainFontColor}>
                    The step-by-step guide below will explain exactly what you need to do:
                </Text>
                <br/>

                <Accordion
                    index={accordionIndex}
                    onChange={handleAccordionItemChange}
                >
                    <PrerequisitesAccordionItem colorPalette={colorPalette}/>

                    <CreateGithubRepositoryAccordionItem colorPalette={colorPalette}/>

                    <GenerateGithubSecretsAccordionItem colorPalette={colorPalette} tokenValid={tokenValid} haveMainEntry={haveMainEntry}/>

                    <CloneGithubRepositoryAccordionItem colorPalette={colorPalette}/>

                    <DownloadStarterPackAccordionItem colorPalette={colorPalette}/>

                    <DownloadCiCdFileAccordionItem colorPalette={colorPalette}/>

                    <CommitBotAccordionItem colorPalette={colorPalette}/>
                </Accordion>
            </Container>
        </Box>
    );
}
