// colour definitions
import { Theme } from "@chakra-ui/react";

export interface IColorDefinition {
    mainFontColor: string;
    titleFontColor: string;
    subTitleFontColor: string;
    accentColor: string;
    linkColor: string;
    primaryButtonColor: string;
    primaryButtonColorHover: string;
    secondaryButtonColor: string;
    secondaryButtonColorHover: string;

    accordionColor: string;

    whiteAlpha: string;

    primaryTheme: string;
    codeColorScheme:any;

    onLinkHover: any;
}

export const getColorDefinitions = (theme: Theme): IColorDefinition => {
    let linkColorHover = theme.colors.primary[400]
    return {

        mainFontColor: theme.colors.navigation[900], //"navigation.900",
        titleFontColor: theme.colors.navigation[700],//"navigation.700",
        subTitleFontColor: theme.colors.gray[800],

        primaryButtonColor: theme.colors.navigation[400],
        primaryButtonColorHover: theme.colors.navigation[500],

        secondaryButtonColor: theme.colors.primary[400],
        secondaryButtonColorHover: theme.colors.primary[500],

        accentColor: theme.colors.primary[400],
        linkColor: theme.colors.primary[600],

        accordionColor: theme.colors.primary[500],
        whiteAlpha: theme.colors.whiteAlpha[900],
        codeColorScheme: "gray",
        primaryTheme: theme.colors.primary,

        onLinkHover: {
            cursor: "pointer",
            color: linkColorHover,
            textDecoration: "underline"
        }
    }
}
