import * as React from 'react';
import {Box, Text} from "@chakra-ui/react";
import {WikiSection} from "../WikiSection";
import {WikiSubSection} from "../WikiSubSection";

interface IWikiCookieSection {}

export const WikiCookieSection: React.FunctionComponent<IWikiCookieSection> = _ => {
    return (
        <Box>
            <WikiSection heading={'Cookie Policy'} >
                <WikiSubSection heading={'What are cookies?'}>
                    <Text color={'black'}>
                        A cookie is a small text file that a website stores on your computer or mobile device when you visit the site.
                        <br /><br />
                        First party cookies are cookies set by the website you’re visiting. Only that website can read them. In addition, a website might potentially use external services, which also set their own cookies, known as third-party cookies.
                        <br /> <br />
                        Persistent cookies are cookies saved on your computer and that are not deleted automatically when you quit your browser, unlike a session cookie, which is deleted when you quit your browser. When you visit our site, you will be able to accept or decline cookies.
                    </Text>
                </WikiSubSection>
                <WikiSubSection heading={'How do we use cookies?'}>
                    <Text color={'black'}>
                        We use both first-party and third-party cookies on our website. First-party cookies are set and maintained by us, while third-party cookies are maintained by external companies. For full functionality of our site,
                        you may need to accept cookies from these external organisations.
                    </Text>
                    <WikiSubSection heading={'Preferences and Functional Cookies'}>
                        These cookies are maintained by our website. Your preferences that are stored will include your acceptance or refusal of our cookie policies. These cookies are also required for certain parts of our website to function (such as authentication or technical services).
                        <br/> <br/>
                        We may place these cookies without your consent.
                    </WikiSubSection>
                    <WikiSubSection heading={"Analytics Cookies"}>
                        Analytics cookies are used internally for our research and are not shared with any third parties. Their purpose is to aid in improving our website and services to you. These cookies assess how you interact with our site as an anonymous user.
                        You are free to decline the use of these cookies.
                    </WikiSubSection>
                    <WikiSubSection heading={"Analytics Cookies"}>
                        A number of our pages display content from third-party content providers. We may also make use of external tracking for marketing purposes. Any such cookies are governed by the respective terms of service and privacy policies of those providers. These third-party providers are outside of our control and may change their terms of service and privacy policies at any time.
                        You are free to decline the use of these cookies; however, this may affect your viewing of certain content on our site.
                    </WikiSubSection>
                </WikiSubSection>
                <WikiSubSection heading={'Managing cookies'}>
                    <Text color={'black'}>
                        We have a few methods to manage cookies on our site.
                        <br />
                        <b>Removing device cookies</b>
                        <br />
                        You can delete all cookies that are already on your device by clearing your browser history. This is found in the Settings section of your browser. Be aware that this may clear all your cookies for all sites you have visited, even those you have opted-in to. You may also lose saved login information and preferences for other websites.
                    </Text>
                </WikiSubSection>
            </WikiSection>
        </Box>
    )
};
