import { Alert, AlertIcon, Box, Button, Divider, Flex, FormControl, FormErrorMessage, FormLabel, Image, Input, InputGroup, InputRightAddon, Link, Stack, Text, useBreakpointValue } from '@chakra-ui/react';
import { AxiosResponse } from 'axios';
import { Field, Form, Formik } from 'formik';
import { FieldAttributes } from 'formik/dist/Field';
import * as React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import * as Yup from 'yup';
import { AuthenticationApi } from '../../api/authentication.api';
import loginImage from '../../assets/images/hackathon/hackathon_card_background.png';
import { tokenAtom } from '../../atoms/token.atom';
import SignInResultModel from '../../models/sign-in-result.model';
import SignInModel from '../../models/sign-in.model';
import { emailRegex } from '../../utils/constants';
import bug_hunt_sign_in from "../../assets/images/sign-in/flux_capacitor_script.png";

interface ISignIn {
}

export const SignIn: React.FunctionComponent<ISignIn> = props => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    let titleFontSize = 45;
    let mainFontColor = 'navigation.700';
    let buttonColor = 'navigation.800';
    const [errorText, setErrorText] = useState('');
    const isMobile = useBreakpointValue({base: true, sm: true, md: true, lg: false});
    const entelectChallengeForumLink = process.env.REACT_APP_ENTELECT_CHALLENGE_FORUM;

    const setToken = useSetRecoilState<string>(tokenAtom);

    const initValues = {
        email: '',
        password: ''
    } as SignInModel;

    const signInValidation =
        Yup.object()
            .shape({
                email: Yup.string()
                    .required('Required')
                    .matches(emailRegex, 'Invalid email address'),
                password: Yup.string()
                    .min(7, 'Incorrect Password length')
                    .required('Required')
            });

    const handleAuthorizationReasons = (result: AxiosResponse<SignInResultModel>) => {
        switch (result.data.authorizationReason) {
            case '':
                setToken(result.data.accessToken);
                history.push({
                    pathname: '/postLogin',
                    state: {from: history.location.state?.from}
                });
                break;
            case 'INVALID_CREDENTIALS':
                setErrorText('Invalid username or password.');
                break;
            case 'NOT_CONFIRMED':
                setErrorText('Account not confirmed.');
                break;
            case 'ACCOUNT_PENDING':
                setErrorText('Email not verified yet. Please check your email.');
                break;
            case 'ACCOUNT_BANNED':
                setErrorText('Account banned.');
                break;
            case 'INVALID_STATE':
                setErrorText('Account in invalid state.');
                break;
            case 'PASSWORD_RESET_REQUIRED':
                setErrorText('Password reset required.');
                break;
            default:
                setErrorText(`An unknown error occurred.`);
                break;
        }
    };

    const determineErrorDisplayText = (errorText: string) => {
        switch (errorText) {
            case 'Password reset required.':
                return <><span>{errorText} <Link style={{fontWeight: 'bold'}} href="/password-reset">Reset password</Link></span></>
            case 'An unknown error occurred.':
                return <>
                    <Stack>
                        <div>{errorText}</div>
                        <div>Please try again later or contact us on our <Link style={{fontWeight: 'bold'}} href={entelectChallengeForumLink} target="_blank" rel="noopener noreferrer">Entelect Challenge Forum</Link></div>
                    </Stack>
                </>
            default:
                return <>{errorText}</>
        }
    }

    const signIn = (values: SignInModel) => {
        setLoading(true);
        setErrorText('');

        AuthenticationApi
            .signIn({email: values.email, password: values.password})
            .then(result => {
                handleAuthorizationReasons(result);
                setLoading(false);
            })
            .catch(reason => {
                setLoading(false);
            });
    };

    const goToRegister = () => history.push('/register');

    return (
        <Flex direction={'row'}>
            <Box width={isMobile ? '100%' : '60%'} alignSelf={'center'} align={'center'}>
                <Box width={isMobile ? '100%' : '60%'} alignSelf={'center'}>
                    <Stack direction={"row"} display={"flex"} align={"baseline"}>
                        <Text
                            py={5}
                            px={isMobile ? 4 : 1}
                            fontWeight={'bold'}
                            fontSize={titleFontSize}
                            color={mainFontColor}
                            align={'center'}>
                            Sign in to Entelect Challenge
                        </Text>

                        <Image
                            h={"2em"}
                            objectFit={"contain"}
                            pos="relative"
                            fit={"contain"}
                            src={bug_hunt_sign_in}
                        />
                    </Stack>

                    <Box>
                        <Divider/>
                    </Box>

                    <Box p={5}>
                        <Formik
                            initialValues={initValues}
                            onSubmit={(values) => signIn(values)}
                            validationSchema={signInValidation}
                        >
                            {({values}) => (
                                <Form>
                                    <Field name={'email'}>
                                        {({field, form}: FieldAttributes<any>) => (
                                            <Box mb={4}>
                                                <FormControl
                                                    id="email"
                                                    isRequired
                                                    isInvalid={form.errors.email && form.touched.email}>
                                                    <FormLabel color={mainFontColor}>Email Address</FormLabel>
                                                    <Input
                                                        {...field}
                                                        colorScheme={'primary'}
                                                        id={'email'}
                                                        type="text"
                                                        size="md"
                                                        variant="filled"
                                                        placeholder={'Email address'}
                                                    />
                                                    <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                                                </FormControl>
                                            </Box>
                                        )}
                                    </Field>
                                    <Field name={'password'}>
                                        {({field, form}: FieldAttributes<any>) => (
                                            <Box my={4}>
                                                <FormControl
                                                    id="password"
                                                    isRequired
                                                    isInvalid={form.errors.password && form.touched.password}>
                                                    <FormLabel color={mainFontColor}>Password</FormLabel>
                                                    <InputGroup colorScheme={'primary'}>
                                                        <Input
                                                            {...field}
                                                            colorScheme={'primary'}
                                                            id={'password'}
                                                            variant={'filled'}
                                                            type={showPassword ? 'text' : 'password'}
                                                            size="md"
                                                            placeholder={'Password'}
                                                        />
                                                        <InputRightAddon borderWidth={0} paddingX={1} children={
                                                            <Button colorScheme={'primary'} variant={'ghost'}
                                                                    size="sm"
                                                                    onClick={() => setShowPassword(!showPassword)}>
                                                                {showPassword ? 'HIDE' : 'SHOW'}
                                                            </Button>
                                                        }/>
                                                    </InputGroup>
                                                    <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                                                </FormControl>
                                            </Box>
                                        )}
                                    </Field>
                                    <Box align={'left'}>
                                        <Link href="/password-reset" textAlign={'left'}>Forgot password?</Link>
                                    </Box>
                                    <Button
                                        w={'100%'}
                                        type={'submit'}
                                        colorScheme={'navigation'}
                                        isLoading={loading}
                                        loadingText={'Signing in'}
                                        backgroundColor={buttonColor}
                                        mt={2}>
                                        SIGN IN
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </Box>

                    <Box p={5}>
                        {
                            errorText.length !== 0 &&
                            <Box>
                                <Alert status="error" variant="left-accent" borderRadius={'md'}>
                                    <AlertIcon/>
                                    {determineErrorDisplayText(errorText)}
                                </Alert>
                            </Box>
                        }
                        <Box>
                            <Divider/>
                            <Text
                                align={'left'}
                                py={5}
                                fontWeight={'bold'}
                                color={mainFontColor}>
                                Don't have an account?
                            </Text>
                            <Button
                                w={'100%'}
                                type={'submit'}
                                variant={'outline'}
                                colorScheme={'navigation'}
                                onClick={goToRegister}>
                                SIGN UP
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {!isMobile &&
                <Box
                    width={'40%'}
                    height={'100vh'}>
                    <Image
                        objectFit={'cover'}
                        height={'100vh'}
                        src={loginImage}
                    />
                </Box>
            }
        </Flex>
    );

};
