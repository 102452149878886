import {HStack} from '@chakra-ui/react';
import * as React from 'react';
import Countdown from 'react-countdown';
import { CountdownRenderProps } from 'react-countdown/dist/Countdown';
import { SingleDateBox } from './SingleDateBox';

interface ICountDown {
    date: Date;
    alternativeProps?: any;
}

export const CountDown: React.FunctionComponent<ICountDown> = props => {

    const alternativeProps = props.alternativeProps;

    const renderer = (props: CountdownRenderProps): React.ReactNode => {
        if (props.completed) {
            // Only show a countdown if the hackathon is yet to be completed
            return <></>
        } else {
            // Render a countdown
            return (
                <HStack spacing={5}>
                    <SingleDateBox date={props.days.toString()} dateName={'days'} alternativeProps={alternativeProps}/>
                    <SingleDateBox date={props.hours.toString()} dateName={'hours'} alternativeProps={alternativeProps}/>
                    <SingleDateBox date={props.minutes.toString()} dateName={'minutes'} alternativeProps={alternativeProps}/>
                </HStack>
            );
        }
    };


    return (
        <>

            <Countdown
                date={props.date}
                renderer={renderer}
            />
        </>
    );
};
