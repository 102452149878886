import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';

export const GoogleAnalyticsPageView: React.FunctionComponent<any> = () => {
    const location = useLocation();
    useEffect(() => {
        // TODO: Determine if this works
        ReactGA.send({ hitType: 'pageview', page: location.pathname });
        // TODO: Determine if this works
        ReactGA.gtag('event', 'page_view', { page_path: location.pathname + location.search });
    }, [location]);
    return null;
};
