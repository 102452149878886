import * as React from 'react';
import {Box, BoxProps, Divider, Grid, GridItem, useColorMode, useTheme, VStack} from "@chakra-ui/react";
import {getColorDefinitions} from "./styling/colorDefinitions";

interface IFancyHorizontalLine {
    cProps?: BoxProps;
    lines?: Array<boolean>;
}

export const FancyHorizontalLine: React.FunctionComponent<IFancyHorizontalLine> = props => {
    const lines = props.lines ? props.lines : [true, true];

    const theme = useTheme();
    const { primaryButtonColor, secondaryButtonColor, secondaryButtonColorHover } = getColorDefinitions(theme);
    const {colorMode,} = useColorMode();

    return (
        <Box {...props.cProps} w={['100%', '100%', '60%', '60%']} mt={[2,2,0,0]}>
            <VStack alignItems={['center', 'center', 'start', 'start']} spacing={0}>
                {lines[0] &&
                <Divider borderColor={colorMode === 'dark' ? primaryButtonColor : primaryButtonColor} opacity={0.8} variant={'solid'}
                         borderWidth={4}
                         w={['16em', '16em', '10em', '10em']}
                         borderRadius={'md'}/>
                }
                {lines[1] &&
                <Grid templateColumns={'repeat(12, 1fr)'} gap={5} >
                    <GridItem colSpan={[5,5,4,4]}>
                        <Divider borderColor={colorMode === 'dark' ? secondaryButtonColorHover : secondaryButtonColor } variant={'solid'}
                                 opacity={0.8} borderWidth={4}
                                 borderRadius={'md'} w={'100%'} transform={['translate(0px, -4px)', 'translate(0px, -4px)', 'translate(90px, -4px)', 'translate(90px, -4px)']}/>
                    </GridItem>
                    <GridItem colSpan={[2,2,3,3]}>
                        <Divider borderColor={colorMode === 'dark' ? secondaryButtonColorHover : secondaryButtonColor } variant={'solid'}
                                 opacity={0.8} borderWidth={4}
                                 borderRadius={'md'} w={'100%'} transform={['translate(0px, -4px)', 'translate(0px, -4px)', 'translate(90px, -4px)', 'translate(90px, -4px)']}/>
                    </GridItem>
                    <GridItem colSpan={[5,5,1,1]}>
                        <Divider borderColor={colorMode === 'dark' ? secondaryButtonColorHover : secondaryButtonColor } variant={'solid'}
                                 opacity={0.8} borderWidth={4}
                                 borderRadius={'md'} w={'100%'} transform={['translate(0px, -4px)', 'translate(0px, -4px)', 'translate(90px, -4px)', 'translate(90px, -4px)']}/>
                    </GridItem>
                </Grid>
                }
            </VStack>
        </Box>
    )
};
