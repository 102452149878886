import {extendTheme} from "@chakra-ui/react";

// USE http://mcg.mbitson.com/#!?primary=%234b5fd3&navigation=%23122547&accent=%23e27693 for color generation based on theme color
export const theme = extendTheme({
    components: {
        Alert: {
            baseStyle: {
                container: {
                    borderRadius: '0.375rem',
                }
            },
        },
    },
    fonts: {
        heading: `'Nunito', sans-serif`,
        body: `'Nunito', sans-serif`,
    },
    colors: {
        primary: {
            '50': '#f2f9ed',
            '100': '#dbedcf',
            '200': '#bfe0a8',
            '300': '#a1d281',
            '400': '#697953', // Tendrill Green
            '500': '#6d8355',
            '600': '#546543',
            '700': '#3f4d33',
            '800': '#2e3a26',
            '900': '#1f281a',
        },
        primaryAlpha: {
            '50': '#f2f9ed',
            '100': '#dbedcf',
            '200': '#bfe0a8',
            '300': '#a1d281',
            '400': '#89a06b', // Tendrill Green
            '500': '#6d8355',
            '600': '#546543',
            '700': '#3f4d33',
            '800': '#2e3a26',
            '900': '#1f281a',
        },
        accent: {
            '50': '#f3f0fa',
            '100': '#e3d6f2',
            '200': '#ccb2e7',
            '300': '#b18dde',
            '400': '#9c4ccb', // Cattleya Orchid
            '500': '#7936a5',
            '600': '#5c2980',
            '700': '#421e5e',
            '800': '#2d1441',
            '900': '#1c0c2b',
        },
        accentAlpha: {
            '50': '#f3f0fa',
            '100': '#e3d6f2',
            '200': '#ccb2e7',
            '300': '#b18dde',
            '400': '#9c4ccb', // Cattleya Orchid
            '500': '#7936a5',
            '600': '#5c2980',
            '700': '#421e5e',
            '800': '#2d1441',
            '900': '#1c0c2b',
        },
        navigation: {
            '50': '#fdece2',
            '100': '#f8ccb6',
            '200': '#f4a67e',
            '300': '#ef8147',
            '400': '#ec6a38', // Mandarin Orange
            '500': '#542415',
            '600': '#9d421f',
            '700': '#73311a',
            '800': '#c85427',
            '900': '#3a180f',
        }
    }
});
