import React from "react";
import {Box, Text, Link as UILink, useTheme} from "@chakra-ui/react";
import {getColorDefinitions} from "../../shared/styling/colorDefinitions";

interface IOfficer {
    name: string;
    tel?: string;
}

interface IProps {
    region: string;
    informationOfficer: IOfficer;
    deputyInformationOfficer?: IOfficer;
    generalEmail?: string;
    dataQueries?: string;
    address: string;
}

export const WikiRegionContactDetails: React.FunctionComponent<IProps> = (props: IProps) => {

    const theme = useTheme();
    const { linkColor, onLinkHover } = getColorDefinitions(theme);

    const defaultTel = '+27 11 994 3300';
    const defaultDeputyInformationOfficer = 'Ryan Naudé';
    const defaultGeneralEmail = "enquiries@entelect.co.za";
    const defaultDataQueries = "privacyqueries@entelect.co.za";


    return (
        <Box>

            <Text fontWeight={"bold"}> Region: </Text> <Text> {props.region}  </Text>
            <br/>
            <Text fontWeight={"bold"}> Information Officer: </Text>

            <Text> Name: {props.informationOfficer.name}  </Text>

            <Text> Tel: {props.informationOfficer.tel || defaultTel}</Text>

            <br/>
            <Text fontWeight={"bold"}> Deputy Officer: </Text>
            <Text> Name: {props.deputyInformationOfficer?.name || defaultDeputyInformationOfficer}  </Text>
            <Text> Tel: {props.deputyInformationOfficer?.tel || defaultTel}</Text>

            <br/>
            <Text>{props.address}</Text>
            <br/>
            <Text> General Email:
                <UILink
                    colorScheme={"primary"}
                    color={linkColor}
                    textDecoration={'underline'}
                    href={`mailto:${props.generalEmail || defaultGeneralEmail}`}
                    _hover={onLinkHover}>
                    {props.generalEmail || defaultGeneralEmail}
                </UILink>
            </Text>
            <Text>Data Queries:
                <UILink
                    colorScheme={"primary"}
                    color={linkColor}
                    textDecoration={'underline'}
                    href={`mailto:${props.dataQueries || defaultDataQueries}`}
                    _hover={onLinkHover}>
                    {props.dataQueries || defaultDataQueries}
                </UILink>
            </Text>
            <br/>
        </Box>
    );

}
