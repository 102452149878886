import { Text } from '@chakra-ui/layout';
import { Flex, Image, Link, Stack, VStack } from '@chakra-ui/react';
import React from 'react';
import entelect_slogan from '../../../assets/images/entelect/Entelect_Slogan_Transparent-white.png';
import { SocialMediaLinks } from '../../footer/SocialMediaLinks';


const main_bg: string = 'navigation.800';
const entelectChallengeForumLink = process.env.REACT_APP_ENTELECT_CHALLENGE_FORUM;

export const UnauthenticatedFooter: React.FunctionComponent = _ => {
    return (
        <Flex bg={main_bg} as="footer" w="100%" zIndex={2} padding={4} alignItems="center" justifyContent="space-between" direction={['column', 'row']}>
            <VStack>
                <SocialMediaLinks social_icon_size="20px" color="whiteAlpha.900" button_props={{ _hover: { bg: 'navigation.700' } }} />
            </VStack>
            <Text fontSize="md" color="whiteAlpha.900" textAlign="center">
                <Link href="/wiki/privacy-policy" isExternal>
                    Privacy Policy
                </Link>
                <span> | </span>
                <Link href="/wiki/cookie-policy" isExternal>
                    Cookie Policy
                </Link>
                <span> | </span>
                &copy; {new Date().getFullYear()} Entelect. All Rights Reserved
            </Text>
            <Stack textAlign="center">
                <Link color="whiteAlpha.900" fontSize="lg" textDecoration="underline" href={entelectChallengeForumLink} isExternal>
                    Entelect Challenge Forum
                </Link>
                <Image h="5em" objectFit="contain" src={entelect_slogan} />
            </Stack>
        </Flex>
    );
};
