import {Box, SimpleGrid, Square} from "@chakra-ui/react";
import * as React from "react";
import {Route, useRouteMatch} from "react-router-dom";
import {WikiCookieSection} from "./wiki-sections/WikiCookiePolicy";
import {WikiPrivacyPolicySection} from "./wiki-sections/WikiPrivacyPolicySection";
import {WikiCompanyRulesSection} from "./wiki-sections/WikiRules.company";
import {WikiSectionNoHackathonTeam} from "./wiki-sections/WikiSectionNoHackathonTeam";
import {WikiHome} from "./WikiHome";
import background from "../../assets/images/main/main_background.png";

interface IWiki {
}

export const Wiki: React.FunctionComponent<IWiki> = props => {
    const {url} = useRouteMatch();

    const textColumnMaxWidth = "92em";
    const minViewH = "50vh";

    return (
        <Box
            w="100%"
            h="100%"
            background={`url(${background})`}
            backgroundSize={'cover'}
            backgroundRepeat={'no-repeat'}
            backgroundPosition={"center"}
            as="main"
            flexGrow={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <Square
                p={5}
                shadow="md"
                borderWidth="0.1em"
                background={"whiteAlpha.800"}
                borderRadius={"0.5em"}
                my={10}
                minWidth={"80%"}
                maxWidth={textColumnMaxWidth}
                minH={minViewH}
                alignSelf={"center"}
            >
                <SimpleGrid>
                    <Box mt={8}>
                        <Route exact path={`${url}/`}>
                            <WikiHome/>
                        </Route>
                        <Route path={`${url}/privacy-policy`}>
                            <WikiPrivacyPolicySection/>
                        </Route>
                        <Route path={`${url}/cookie-policy`}>
                            <WikiCookieSection/>
                        </Route>
                        <Route path={`${url}/hackathon-rules`}>
                            <WikiCompanyRulesSection/>
                        </Route>
                        <Route path={`${url}/no-hackathon-team`}>
                            <WikiSectionNoHackathonTeam/>
                        </Route>
                    </Box>
                </SimpleGrid>
            </Square>

        </Box>
    );
};
