import {AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Code, ListItem, OrderedList} from "@chakra-ui/react";
import * as React from "react";
import {FunctionComponent} from "react";
import {useHistory} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {isTokenValid} from "../../../atoms/token.atom";

interface IProps {
    colorPalette: any;
}

export const CommitBotAccordionItem: FunctionComponent = (props: IProps) => {
    const history = useHistory();

    const tokenValid = useRecoilValue(isTokenValid);

    const handleGoToSubmission = () => {
        history.push('/portal/entelect-challenge');
    }

    const { mainFontColor, accordionColor, codeColorScheme, primaryButtonColor, primaryButtonColorHover } = props.colorPalette;

    return (
        <AccordionItem>
            <h2>
                <AccordionButton _expanded={{ bg: accordionColor, color: 'white' }}>
                    <Box flex='1' textAlign='left'>
                        Commit and Push Your Bot
                    </Box>
                    <AccordionIcon/>
                </AccordionButton>
            </h2>
            <AccordionPanel pb={4} color={mainFontColor}>
                Last step! You are now going to commit and push your bot to Github.

                <br/>
                <br/>

                <OrderedList>
                    <ListItem>
                        In the folder where your bot is. Open your preferred command prompt.
                    </ListItem>
                    <ListItem>
                        Enter the following, <Code colorScheme={codeColorScheme}>git add .</Code>. This will track all your bot code files.
                    </ListItem>
                    <ListItem>
                        Then enter <Code colorScheme={codeColorScheme}>git commit -m "My first EC commit"</Code>. This will commit your bot code files.
                    </ListItem>
                    <ListItem>
                        Lastly, enter <Code colorScheme={codeColorScheme}>git push</Code>. This will push your code to Github.
                    </ListItem>
                    <ListItem>
                        On your Github repository, go to <b>Actions</b> to see your bot's build progress, as well as the build logs.
                    </ListItem>
                </OrderedList>

                <br/>

                Congratulations and thank you for completing the Getting Started guide.

                <br/>


                Your code is now pushed to Github, where it will be build and submitted to the Entelect Challenge servers.

                <br/>


                You may now go see your submissions.

                <br/>
                <br/>

                <Button
                    color={'white'}
                    backgroundColor={primaryButtonColor}
                    disabled={!tokenValid}
                    _hover={{
                        backgroundColor: primaryButtonColorHover
                        }}
                    onClick={handleGoToSubmission}>
                    Go to Submissions
                </Button>
            </AccordionPanel>
        </AccordionItem>
    );
}
