import { Box, Center, Grid, GridItem, Image, SimpleGrid, Text, useBreakpointValue } from "@chakra-ui/react";
import * as React from "react";
import { useRouteMatch } from "react-router-dom";
import wiki_character_left from "../../assets/images/wiki/wiki_character_1.png";
import wiki_character_right from "../../assets/images/wiki/wiki_character_2.png";
import { FancyHeading } from "../../shared/FancyHeading";
import { WikiCategoryCard } from "./WikiCategoryCard";
import paia_manual from "../../assets/pdf/Entelect-Software-PAIA-Manual-v1.1.3e3b2aec.pdf";

interface IWikiHome {
}

export const WikiHome: React.FunctionComponent<IWikiHome> = _ => {
    const {path} = useRouteMatch();

    const isMobile = useBreakpointValue({base: true, sm: true, md: true, lg: false});
    const textFontSize = "calc(1em + 1vmin)";

    const openManual = () => window.open(paia_manual, "_blank");

    return (
        <Box>
            <Grid
                templateColumns={"repeat(5, 1fr)"}
            >
                <GridItem colSpan={1}>
                    {
                        !isMobile &&
                        <Center>
                            <Image src={wiki_character_left} h={["5em", "5em", "10em", "15em"]}/>
                        </Center>
                    }
                </GridItem>
                <GridItem colSpan={3}>
                    <Center h={"100%"}>
                        <Box>
                            <FancyHeading heading={"Entelect Challenge Knowledge Base"} headingSize={"4xl"} headingColor={"primary.700"}/>
                            <Text mt={2} color={"navigation.500"} w={["100%", "100%", "100%", "80%"]} fontSize={textFontSize} textAlign={["center", "center", "left", "left"]}>
                                Here you can find all the information to get you going with The Entelect Challenge.
                                Below you will find categories that you can browse for the questions you have.
                            </Text>
                        </Box>
                    </Center>
                </GridItem>
                <GridItem colSpan={1}>
                    {
                        !isMobile &&
                        <Center>
                            <Image src={wiki_character_right} h={["5em", "5em", "10em", "15em"]}/>
                        </Center>
                    }
                </GridItem>
            </Grid>

            <SimpleGrid columns={[1, 3, 3, 5]} spacing={5} mt={16}>
                <WikiCategoryCard name={"Privacy Policy"} path={`${path}privacy-policy`}/>
                <WikiCategoryCard name={"Cookie Policy"} path={`${path}cookie-policy`}/>
                <WikiCategoryCard name={"Hackathon Rules"} path={`${path}hackathon-rules`}/>
                <WikiCategoryCard name={"No Hackathon Team"} path={`${path}no-hackathon-team`}/>
                <WikiCategoryCard name={"PAIA Manual"} path={path} onCustomClick={openManual}/>
            </SimpleGrid>
        </Box>
    );
};
