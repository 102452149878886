import {Box, Button, Divider, Flex, Image, Link as UILink, Square, Text, useBreakpointValue, useTheme} from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";
import university_character_top_left from "../../../assets/images/university/buzzing_for_university_cup.png";
import university_character_left from "../../../assets/images/university/university_character_left.png";
import university_character_right from "../../../assets/images/university/university_character_right.png";
import university_cup_logo from "../../../assets/images/university/entelect_challenge_university_cup_logo.svg";
import { getCurrentYear } from "../../../utils/hooks/general-utils";
import background from "../../../assets/images/main/main_background.png";
import {getColorDefinitions} from "../../../shared/styling/colorDefinitions";

interface IUnauthenticatedUniversity {
}

export const UnauthenticatedUniversity: React.FunctionComponent<IUnauthenticatedUniversity> = _ => {
    let imageMaxWidth = "40em";
    let textColumnMaxWidth = "96em";
    let titleFontSize = 45;
    let minViewH = "50vh";
    let otherTextFontSize = "calc(1.2em + 1vmin)";

    const theme = useTheme();
    const { titleFontColor, mainFontColor,
        linkColor, onLinkHover,
        primaryButtonColor, primaryButtonColorHover,
        secondaryButtonColor, secondaryButtonColorHover,
    } = getColorDefinitions(theme);


    const history = useHistory();

    const isMobile = useBreakpointValue({base: true, sm: true, md: true, lg: false});

    return (
        <Box
            background={`url(${background})`}
            backgroundSize={'cover'}
            backgroundRepeat={'no-repeat'}
            backgroundPosition={"center"}
            as="main">
            <Flex flexDirection={"column"} paddingTop={'5em'} paddingBottom={'5em'}>

                <Square
                    p={5} shadow="md" borderWidth="0.1em"
                    background={"whiteAlpha.800"}
                    borderRadius={"0.5em"}
                    mt={25}
                    mb={5}
                    width={"80%"}
                    pb={5}
                    maxWidth={textColumnMaxWidth}
                    minH={minViewH}
                    alignSelf={"center"}
                    flexDirection={"column"}>
                    <Box
                        align={"center"}
                        py={5}
                        width={"80%"}>
                        <Image
                            alignSelf={"center"}
                            htmlWidth={"80%"}
                            maxWidth={imageMaxWidth}
                            objectFit="cover"
                            src={university_cup_logo}
                            alt={`University Cup ${getCurrentYear()} Logo`}
                        />
                    </Box>
                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        The Entelect Challenge University Cup is a one-day hackathon where teams of students from
                        various universities around the country are challenged to solve a coding problem.
                        The hackathons usually take place from 10:00 to 15:00 on the day.
                    </Text>
                    <Divider/>
                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        We have created this unique initiative specifically for students, as we noticed that there is a
                        lot of interest in the main challenge but time commitments can be problematic given academic
                        schedules.
                    </Text>
                    <Divider/>
                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        We hope to give our future technology leaders a chance to flex their brain muscles and
                        participate in something fun and rewarding!
                    </Text>
                </Square>

                <Square
                    width={"60%"}
                    maxWidth={textColumnMaxWidth}
                    h={"0vh"}
                    alignSelf={"center"}
                    flexDirection={"column"}>
                    <Image
                        h={"10em"}
                        objectFit={"contain"}
                        pos="relative" top="1vw" left="-50%"
                        fit={"contain"}
                        src={university_character_top_left}
                    />
                </Square>

                {!isMobile &&
                    <Square
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        h={"0vh"}
                        alignSelf={"center"}
                        flexDirection={"column"}>
                        <Image
                            h={"20em"}
                            objectFit={"contain"}
                            pos="relative" top="1vw" left="50%"
                            fit={"contain"}
                            src={university_character_right}
                        />
                    </Square>
                }

                <Square
                    p={5} shadow="md" borderWidth="0.1em"
                    background={"whiteAlpha.800"}
                    borderRadius={"0.5em"}
                    my={5}
                    width={"80%"}
                    pb={5}
                    maxWidth={textColumnMaxWidth}
                    minH={minViewH}
                    alignSelf={"center"}
                    flexDirection={"column"}>
                    <Text
                        fontWeight={"bold"}
                        fontSize={titleFontSize}
                        color={titleFontColor}
                        align={"center"}
                        pb={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        colorScheme="primary">
                        How to Enter
                    </Text>
                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        <UILink colorScheme={"primary"}
                                color={linkColor}
                                textDecoration={'underline'}
                                href="/portal"
                                _hover={onLinkHover}>
                            Log in/Sign up
                        </UILink>
                        {" "} on the Entelect Challenge player portal.
                    </Text>

                    <Box
                        align={"center"}
                        flexDirection={"row"}
                    >
                        <Button
                            m={5}
                            size={"lg"}
                            backgroundColor={secondaryButtonColor}
                            colorScheme={"navigation"}
                            color={"whiteAlpha.900"}
                            _hover={{cursor: "pointer",
                                    backgroundColor: secondaryButtonColorHover}}
                            as="a"
                            onClick={() => history.push("/signin")}
                        >
                            LOG IN
                        </Button>

                        <Button
                            m={5}
                            size={"lg"}
                            backgroundColor={primaryButtonColor}
                            colorScheme={"primary"}
                            color={"whiteAlpha.900"}
                            _hover={{cursor: "pointer",
                                    backgroundColor: primaryButtonColorHover}}
                            as="a"
                            onClick={() => history.push("/register")}
                        >
                            SIGN UP
                        </Button>
                    </Box>

                    <Divider/>
                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        We will open registration and team creation closer to the hackathon, but start finding friends
                        now to be part of your team.
                    </Text>
                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        You will receive alerts via email when team creation opens, when the practice problem is
                        released, and the date/logistics for the University Cup event is announced.
                    </Text>
                </Square>

                {!isMobile &&
                    <Square
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        h={"0vh"}
                        alignSelf={"center"}
                        flexDirection={"column"}>
                        <Image
                            h={"20em"}
                            objectFit={"contain"}
                            pos="relative" top="1vw" left="-50%"
                            fit={"contain"}
                            src={university_character_left}
                        />
                    </Square>
                }

                <Square
                    p={5} shadow="md" borderWidth="0.1em"
                    background={"whiteAlpha.800"}
                    borderRadius={"0.5em"}
                    my={5}
                    width={"80%"}
                    pb={5}
                    maxWidth={textColumnMaxWidth}
                    minH={minViewH}
                    alignSelf={"center"}
                    flexDirection={"column"}>
                    <Text
                        fontWeight={"bold"}
                        fontSize={titleFontSize}
                        color={titleFontColor}
                        align={"center"}
                        pb={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        colorScheme="primary">
                        Take Note
                    </Text>
                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        The University Cup is open to students registered at a university in South Africa.
                    </Text>
                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        This includes undergraduate students as well as full-time Honours students.
                        Unfortunately, if you are working part-time whilst doing your honours, you may not enter the University Cup Challenge.
                    </Text>
                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        The University Cup is team-based.
                        Each team must consist of no less than two, and no more than three individuals.
                    </Text>
                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        The University Cup will take place over a single Saturday, one in April and one in July.
                        The event will be hosted online for participating University campuses.
                    </Text>
                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        Submissions will be done online via the Entelect Challenge player portal.
                    </Text>
                    {/*TODO: terms and conditions*/}
                </Square>

                <Square
                    p={5} shadow="md" borderWidth="0.1em"
                    background={"whiteAlpha.800"}
                    borderRadius={"0.5em"}
                    my={5}
                    width={"80%"}
                    pb={5}
                    maxWidth={textColumnMaxWidth}
                    minH={minViewH}
                    alignSelf={"center"}
                    flexDirection={"column"}>
                    <Text
                        fontWeight={"bold"}
                        fontSize={titleFontSize}
                        color={titleFontColor}
                        align={"center"}
                        width={"80%"}
                        pb={5}
                        maxWidth={textColumnMaxWidth}
                        colorScheme="primary">
                        Entelect’s Graduate Programme
                    </Text>

                    <Divider/>
                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        Every year, Entelect takes on some of South Africa's brightest young university graduates, who
                        join us with a mix of engineering, science and commerce degrees. These chosen ones participate
                        in an intensive training programme, structured to accelerate their careers – giving them a clear
                        advantage over their university peers.
                    </Text>
                    <Divider/>
                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        Would you like to be invited to one of our Graduate Open Days, to learn even more?
                    </Text>
                    <Box
                        align={"center"}
                        flexDirection={"row"}
                    >
                        <Button
                            m={5}
                            size={"lg"}
                            backgroundColor={secondaryButtonColor}
                            colorScheme={"navigation"}
                            color={"whiteAlpha.900"}
                            _hover={{cursor: "pointer",
                                    backgroundColor: secondaryButtonColorHover}}
                            as="a"
                            href="https://form.typeform.com/to/mxE9xY?typeform-source=entelectsocial.typeform.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            APPLY FOR OPEN DAY
                        </Button>
                    </Box>
                </Square>
            </Flex>
        </Box>
    );
};
