import {AspectRatio, Box, Button, Flex, Grid, GridItem, Image, ListItem, Square, Stack, StackDivider, Text, UnorderedList, useBreakpointValue, useTheme, VStack} from "@chakra-ui/react";
import * as React from "react";
import { ReactNode } from "react";
import { HistoryItem } from "../../../models/history-item.model";
import { challengeGamesHistory } from "./challenge-games-history";
import history from "../../../assets/images/legacy/history.png";
import background from "../../../assets/images/main/main_background.png";
import {getColorDefinitions} from "../../../shared/styling/colorDefinitions";

interface IUnauthenticatedLegacy {
}

export const UnauthenticatedLegacy: React.FunctionComponent<IUnauthenticatedLegacy> = _ => {
    let textColumnMaxWidth = "80%";
    let titleFontSize = 50;
    let subTitleFontSize = 40;
    let minViewH = "50vh";
    let otherTextFontSize = "calc(1.2em + 1vmin)";

    const theme = useTheme();
    const { titleFontColor, accentColor, mainFontColor, subTitleFontColor,
        primaryButtonColor, primaryButtonColorHover } = getColorDefinitions(theme);

    const isMobile = useBreakpointValue({base: true, sm: true, md: true, lg: false});

    const displayLegacyGameForTabletsOrLarger = (challengeGame: HistoryItem): ReactNode => {
        return (
            <>
                <Grid
                    key={challengeGame.fullYear + "_" + challengeGame.title}
                    templateColumns="repeat(2, minmax(0, 1fr))" gap={5} alignItems={"center"}
                    p={5}>
                    <GridItem>
                        <Square
                            alignSelf={"center"}
                            flexDirection={"column"}>
                            <Text
                                fontWeight={"bold"}
                                fontSize={subTitleFontSize}
                                color={subTitleFontColor}
                                align={"left"}
                                w={"100%"}
                                pb={5}
                                colorScheme="primary">
                                {challengeGame.title} - {challengeGame.year}
                            </Text>
                            <Text
                                fontSize={otherTextFontSize}
                                color={mainFontColor}
                                py={5}
                                align={"left"}
                                colorScheme="primary">
                                {challengeGame.summary}
                            </Text>
                        </Square>

                        {/* TODO: Not sure if this ever worked? Check if we need it */}
                        {/*<UILink*/}
                        {/*	colorScheme={'primary'}*/}
                        {/*	fontSize={linkFontSize}*/}
                        {/*	color={linkColor}*/}
                        {/*	href={`${path}/${challengeGame.fullYear}`}>*/}
                        {/*	Read more*/}
                        {/*</UILink>*/}
                    </GridItem>
                    <GridItem>
                        <AspectRatio ratio={16 / 9} zIndex={999}>
                            <iframe
                                src={challengeGame.videoUrl}
                                style={{
                                    borderRadius: "0.5em",
                                    border: "0"
                                }}
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen>
                            </iframe>
                        </AspectRatio>
                    </GridItem>
                </Grid>
            </>
        );
    };

    const displayLegacyGameForMobile = (challengeGame: HistoryItem): ReactNode => {
        return (
            <>
                <Square
                    key={challengeGame.fullYear + "_" + challengeGame.title}
                    alignSelf={"center"}
                    flexDirection={"column"}
                    width={"100%"}
                >
                    <Stack
                        width={"100%"}>
                        <Text
                            fontWeight={"bold"}
                            fontSize={subTitleFontSize}
                            color={"accent.600"}
                            align={"left"}
                            w={"100%"}
                            pb={5}
                            colorScheme="primary">
                            {challengeGame.title} - {challengeGame.year}
                        </Text>

                        <AspectRatio ratio={16 / 9} zIndex={999} width={"100%"}>
                            <iframe
                                src={challengeGame.videoUrl}
                                style={{
                                    width: "100%",
                                    borderRadius: "0.5em",
                                    border: "0"
                                }}
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen>
                            </iframe>
                        </AspectRatio>
                    </Stack>
                </Square>
            </>
        );
    };

    return (
        <Box
            background={`url(${background})`}
            backgroundSize={'cover'}
            backgroundRepeat={'no-repeat'}
            backgroundPosition={"center"} as="main">
            <Flex flexDirection={"column"}>
                <Square p={5} shadow="md" borderWidth="0.1em"
                        background={"whiteAlpha.800"}
                        borderRadius={"0.5em"}
                        mt={10}
                        width={"100%"}
                        maxWidth={textColumnMaxWidth}
                        minH={minViewH}
                        alignSelf={"center"}
                        flexDirection={"column"}>

                    <Text
                        fontWeight={"bold"}
                        fontSize={titleFontSize}
                        color={mainFontColor}
                        align={"center"}
                        pb={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        colorScheme="primary">
                        About Entelect
                    </Text>

                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"justify"}
                        colorScheme="primary">
                        At Entelect, we deliver solutions that enable businesses to reach their full potential.
                        We offer end-to-end technology services and solutions, collaborating with our customers to help them go from good to great.
                    </Text>

                    <Text
                        fontSize={otherTextFontSize}
                        color={accentColor}
                        py={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"justify"}
                        fontWeight={"bold"}
                        colorScheme="primary">
                        Our fantastic team of like-minded tech heroes use their abilities for good by:
                    </Text>
                    <UnorderedList
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        p={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"justify"}
                        colorScheme="primary">
                        <ListItem>Making a difference through educating underprivileged communities.</ListItem>
                        <ListItem>Elevating the profile of technology and technologists in South Africa.</ListItem>
                    </UnorderedList>
                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"justify"}
                        colorScheme="primary">
                        Since opening our doors in 2001, we have enjoyed exceptional growth, both as a company and as individuals.
                        Our journey has taken us from our Joburg HQ to opening offices in
                        Cape Town, Centurion, Durban, Zimbabwe, the UK, Australia, New Zealand (deep in Hobbit country), and the Netherlands (watch out for the bikes!).
                        Our core philosophy is to hire the best and give them what they need to become more.
                    </Text>

                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"justify"}
                        colorScheme="primary">
                        We work with people who are passionate about their craft and technology, driven to bring their unique contributions into a team environment.
                        We value growth, collaboration, relationships, fun, technical excellence, and using all of the above to get things done!
                        We are passionate about cultivating the software and technology community by sharing our knowledge and learning from others.
                        The Entelect Challenge is one of the ways we aim to bring the community together.
                    </Text>
                    <Button
                        href={"https://culture.entelect.co.za/"}
                        m={5}
                        size={"lg"}
                        backgroundColor={primaryButtonColor}
                        colorScheme={"navigation"}
                        color={"whiteAlpha.900"}
                        target={"_blank"}
                        as={"a"}
                        _hover={{
                            cursor: "pointer",
                            backgroundColor: primaryButtonColorHover
                        }}
                    >
                        LEARN MORE
                    </Button>
                </Square>

                {!isMobile &&
                    <Square
                        width={"100%"}
                        maxWidth={textColumnMaxWidth}
                        h={"auto"}
                        alignSelf={"center"}
                        flexDirection={"column"}>
                    </Square>
                }

                <Square
                    width={"60%"}
                    maxWidth={textColumnMaxWidth}
                    h={"0vh"}
                    alignSelf={"center"}
                    flexDirection={"column"}>
                    <Image
                        h={"10em"}
                        objectFit={"contain"}
                        pos="relative" top="1vw" left="-50%"
                        fit={"contain"}
                        src={history}
                    />
                </Square>

                <Square p={5} shadow="md" borderWidth="0.1em"
                        background={"whiteAlpha.800"}
                        borderRadius={"0.5em"}
                        my={10}
                        width={"100%"}
                        maxWidth={textColumnMaxWidth}
                        minH={minViewH}
                        alignSelf={"center"}
                        flexDirection={"column"}>

                    <Text
                        fontWeight={"bold"}
                        fontSize={titleFontSize}
                        color={titleFontColor}
                        align={"center"}
                        py={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        colorScheme="primary">
                        History of the Entelect Challenge
                    </Text>

                    <VStack divider={
                        <StackDivider
                            borderWidth={2}
                            backgroundColor={"navigation.200"}
                            borderColor="navigation.200"/>
                    }
                            w={"100%"}
                            spacing={4}
                            align="stretch">
                        {challengeGamesHistory.map((challengeGame, _) => (
                            isMobile ? (
                                displayLegacyGameForMobile(challengeGame)
                            ) : (
                                displayLegacyGameForTabletsOrLarger(challengeGame)
                            )
                        ))}
                    </VStack>
                </Square>

            </Flex>
        </Box>
    );
};

