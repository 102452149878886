import {AspectRatio, Box, Button, Divider, Flex, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Square, Text, useBreakpointValue, useDisclosure, useTheme} from "@chakra-ui/react";
import React from "react";
import {useHistory} from "react-router-dom";
import {useRecoilValue, useSetRecoilState} from "recoil";
import banner_left_image from "../../../assets/images/main/banner-left.png";
import prizes from "../../../assets/images/main/prizes.svg";
import step_1_image from "../../../assets/images/main/step-1.png";
import step_3_image from "../../../assets/images/main/step-3.png";
import background from "../../../assets/images/main/main_background.png";
import read_the_rules from "../../../assets/images/home/read_the_rules.png";
import {getSettingAsMap, SETTING_NAME_UNAUTHENTICATED} from "../../../atoms/settings.atom";
import {gettingStartedAtom} from "../../../atoms/getting-started.atom";
import {GettingStartedAccordionItems} from "../../../models/accordion-items.enum";
import {getColorDefinitions} from "../../../shared/styling/colorDefinitions";

interface IUnauthenticatedHome {
}

export const UnauthenticatedHome: React.FunctionComponent<IUnauthenticatedHome> = _ => {
    let textColumnMaxWidth = "92em";
    let titleFontSize = 50;
    let subTitleFontSize = 27;
    let minViewH = "50vh";
    let otherTextFontSize = "calc(1.2em + 1vmin)";

    const theme = useTheme();
    const {
        mainFontColor, titleFontColor, subTitleFontColor,
        primaryButtonColor,
        secondaryButtonColor
    } = getColorDefinitions(theme);


    const history = useHistory();

    const {isOpen, onClose} = useDisclosure();
    const isMobile = useBreakpointValue({base: true, sm: true, md: true, lg: false});
    const entelectChallengeForumLink = process.env.REACT_APP_ENTELECT_CHALLENGE_FORUM;

    const youtubeVideoMap = useRecoilValue(getSettingAsMap(SETTING_NAME_UNAUTHENTICATED.YOUTUBE_VIDEO_MAP));
    const setAccordionIndex = useSetRecoilState(gettingStartedAtom);

    const handleDownloadCiCdNavigation = () => {
        setAccordionIndex(GettingStartedAccordionItems.DownloadCiCdFile);
        history.push("/getting-started");
    };

    return (
        <Box
            mt={"1em"}
            background={`url(${background})`}
            backgroundSize={'cover'}
            backgroundRepeat={'no-repeat'}
            backgroundPosition={"center"}
            as="main">
            <Flex flexDirection={"column"} paddingTop={'5em'} paddingBottom={'5em'}>
                <Square p={5} shadow="md" borderWidth="0.1em"
                        background={"whiteAlpha.800"}
                        borderRadius={"0.5em"}
                        mb={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        minH={minViewH}
                        alignSelf={"center"}
                        flexDirection={"column"}
                        marginTop={5}>
                    <Box
                        align={"center"}
                        py={5}
                        width={"80%"}>
                        <Text
                            fontWeight={"bold"}
                            fontSize={titleFontSize}
                            color={titleFontColor}
                            align={"center"}
                            maxWidth={textColumnMaxWidth}
                            colorScheme="primary"
                            as={"span"}>
                            Welcome to the Entelect Challenge!
                        </Text>
                    </Box>

                    <Text
                        fontWeight={"bold"}
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        What is Entelect Challenge?
                    </Text>
                    <Divider/>
                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        It is an initiative run by Entelect with the aim of giving the best programming talent in the country various opportunities to show off their skills and win some awesome prizes.
                        Each year, the Entelect Challenge team selects a new and exciting theme for the various tournaments and hackathons.
                    </Text>
                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        The Entelect Challenge is comprised of two types of competitions: The Main Challenge, which runs throughout the year, and Hackathons, which are one-day events.
                    </Text>
                    <Divider/>
                    <Text
                        fontWeight={"bold"}
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        What skills do I need?
                    </Text>
                    <Divider/>
                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        Just some basic coding skills! For both Hackathons and the Main Challenge we cater for a variety of programming languages.
                    </Text>
                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        Additionally, we host a Build-A-Bot Workshop annually to assist interested people to get going on a starter bot for the Main Challenge.
                    </Text>
                    <Divider/>
                    <Text
                        fontWeight={"bold"}
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        Which event do I qualify for?
                    </Text>
                    <Divider/>
                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        The Main Challenge is open to the public and runs from March to September each year. Participants develop bots to play a game created by the Entelect Challenge team.
                        Throughout the year, tournaments determine the top 8 bots, which compete in the grand finale at Comic Con Africa.
                    </Text>
                    <Box
                        align={"center"}
                        flexDirection={"row"}>
                        <Button
                            m={5}
                            onClick={() => history.push("/game")}
                            width={"40%"}
                            size={"lg"}
                            backgroundColor={primaryButtonColor}
                            colorScheme={"navigation"}
                            color={"whiteAlpha.900"}
                            target={"_self"}
                            as={"a"}>
                            CURRENT GAME
                        </Button>
                        <Button
                            m={5}
                            onClick={() => history.push("/getting-started")}
                            width={"40%"}
                            size={"lg"}
                            backgroundColor={secondaryButtonColor}
                            colorScheme={"primary"}
                            color={"whiteAlpha.900"}
                            target={"_self"}
                            as={"a"}>
                            LET'S GO!
                        </Button>
                    </Box>
                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        We host three hackathons annually: two exclusively for university students and one dedicated to IITPSA members.
                        A hackathon is a one day event whereby participants compete in a team or individually to solve a problem statement.
                    </Text>
                    <Box
                        align={"center"}
                        flexDirection={"row"}>
                        <Button
                            onClick={() => history.push("/university")}
                            m={5}
                            size={"lg"}
                            width={"40%"}
                            backgroundColor={primaryButtonColor}
                            colorScheme={"navigation"}
                            color={"whiteAlpha.900"}
                            target={"_self"}
                            as={"a"}>
                            UNIVERSITY CUP
                        </Button>
                        <Button
                            m={5}
                            onClick={() => history.push("/portal")}
                            size={"lg"}
                            width={"40%"}
                            backgroundColor={secondaryButtonColor}
                            colorScheme={"primary"}
                            color={"whiteAlpha.900"}
                            target={"_self"}
                            as={"a"}>
                            SIGN ME UP!
                        </Button>
                    </Box>
                </Square>

                <Square p={5} shadow="md" borderWidth="0.1em"
                        background={"whiteAlpha.800"}
                        borderRadius={"0.5em"}
                        my={5}
                        sx={{width: "80%"}}
                        maxWidth={textColumnMaxWidth}
                        minH={minViewH}
                        alignSelf={"center"}
                        flexDirection={"column"}>

                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        Entelect opened its doors in 2001, and the Entelect Challenge has been running since 2012, with the Finals taking place at Comic Con Africa and rAge throughout the years.
                    </Text>
                    <Box
                        align={"center"}
                        flexDirection={"row"}>
                        <Button
                            m={5}
                            size={"lg"}
                            width={"40%"}
                            backgroundColor={secondaryButtonColor}
                            colorScheme={"primary"}
                            color={"whiteAlpha.900"}
                            onClick={() => history.push("/legacy")}>
                            CHALLENGE HISTORY
                        </Button>
                        <Button
                            m={5}
                            href={"https://culture.entelect.co.za/"}
                            size={"lg"}
                            width={"40%"}
                            backgroundColor={primaryButtonColor}
                            colorScheme={"navigation"}
                            color={"whiteAlpha.900"}
                            target={"_blank"}
                            as={"a"}>
                            ABOUT ENTELECT
                        </Button>
                    </Box>
                </Square>

                {!isMobile &&
                    <Square
                        sx={{width: "80%", height: "0vh"}}
                        maxWidth={textColumnMaxWidth}
                        alignSelf={"center"}
                        flexDirection={"column"}>
                        <Image
                            h={"20em"}
                            objectFit={"contain"}
                            pos="relative" top="-10vw" left="-50%"
                            fit={"contain"}
                            src={banner_left_image}
                        />
                    </Square>
                }

                {!isMobile &&
                    <Square
                        sx={{width: "20%", height: "0vh"}}
                        maxWidth={textColumnMaxWidth}
                        alignSelf={"center"}
                        flexDirection={"column"}>
                        <Image
                            w={"80vw"}
                            objectFit={"contain"}
                            pos="relative" top="0" left="35vw"
                            fit={"contain"}
                            src={read_the_rules}
                        />
                    </Square>
                }

                <Square p={5} shadow="md" borderWidth="0.1em"
                        background={"whiteAlpha.800"}
                        borderRadius={"0.5em"}
                        mb={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        minH={minViewH}
                        alignSelf={"center"}
                        flexDirection={"column"}>
                    <Box
                        align={"center"}
                        py={5}
                        width={"80%"}>
                        <Text
                            fontWeight={"bold"}
                            fontSize={titleFontSize}
                            color={titleFontColor}
                            align={"center"}
                            maxWidth={textColumnMaxWidth}
                            colorScheme="primary"
                            as={"span"}>
                            Eager to get started?
                        </Text>
                    </Box>

                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        Follow these simple steps to get going on your 'bot' to compete in the Main Challenge. This is not applicable to Hackathons.
                    </Text>

                    <Text
                        fontWeight={"bold"}
                        fontSize={subTitleFontSize}
                        color={subTitleFontColor}
                        align={"center"}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        colorScheme="primary">
                        STEP 1: REGISTER
                    </Text>

                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        You will need to register on the Player Portal in order to get started.
                    </Text>
                    <Button
                        m={5}
                        size={"lg"}
                        backgroundColor={primaryButtonColor}
                        colorScheme={"navigation"}
                        color={"whiteAlpha.900"}
                        onClick={() => history.push("/portal")}>
                        PLAYER PORTAL
                    </Button>

                    <Text
                        fontWeight={"bold"}
                        fontSize={subTitleFontSize}
                        color={subTitleFontColor}
                        align={"center"}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        colorScheme="primary">
                        STEP 2: DOWNLOAD THE CI/CD FILE
                    </Text>

                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        Follow the instructions in the getting started page to create a GitHub account.
                    </Text>
                    <Button
                        m={5}
                        size={"lg"}
                        backgroundColor={primaryButtonColor}
                        colorScheme={"navigation"}
                        color={"whiteAlpha.900"}
                        onClick={handleDownloadCiCdNavigation}>
                        DOWNLOAD CI/CD
                    </Button>

                    <Text
                        fontWeight={"bold"}
                        fontSize={subTitleFontSize}
                        color={subTitleFontColor}
                        align={"center"}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        colorScheme="primary">
                        STEP 3: IMPROVE
                    </Text>
                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"70%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        Change some of the provided logic, or code your own into one of the given starter
                        bots and follow the streamlined GitHub automatic submission process.
                    </Text>
                    <Box
                        align={"center"}
                        flexDirection={"row"}>

                        <Button
                            href={"https://github.com/EntelectChallenge/2025-Zooscape"}
                            m={5}
                            size={"lg"}
                            backgroundColor={primaryButtonColor}
                            colorScheme={"navigation"}
                            color={"whiteAlpha.900"}
                            target={"_blank"}
                            as={"a"}>
                            STARTER PACK
                        </Button>
                        <Button
                            m={5}
                            href={entelectChallengeForumLink}
                            size={"lg"}
                            backgroundColor={secondaryButtonColor}
                            colorScheme={"primary"}
                            color={"whiteAlpha.900"}
                            target={"_blank"}
                            as={"a"}>
                            JOIN THE COMMUNITY
                        </Button>
                    </Box>
                </Square>

                {!isMobile &&
                    <Square
                        sx={{width: "20%", height: "0vh"}}
                        maxWidth={textColumnMaxWidth}
                        alignSelf={"center"}
                        flexDirection={"column"}>
                        <Image
                            w={"80vw"}
                            objectFit={"contain"}
                            pos="relative" top="0" left="-35vw"
                            fit={"contain"}
                            src={step_1_image}
                        />
                    </Square>
                }

                {!isMobile &&
                    <Square
                        sx={{width: "20%", height: "0vh"}}
                        maxWidth={textColumnMaxWidth}
                        alignSelf={"center"}
                        flexDirection={"column"}>
                        <Image
                            w={"80vw"}
                            objectFit={"contain"}
                            pos="relative" top="0" left="35vw"
                            fit={"contain"}
                            src={step_3_image}
                        />
                    </Square>
                }

                <Square p={5} shadow="md" borderWidth="0.1em"
                        background={"whiteAlpha.800"}
                        borderRadius={"0.5em"}
                        my={5}
                        sx={{width: "80%"}}
                        maxWidth={textColumnMaxWidth}
                        minH={minViewH}
                        alignSelf={"center"}
                        flexDirection={"column"}>
                    <Box
                        align={"center"}
                        py={5}
                        width={"80%"}>
                        <Text
                            fontWeight={"bold"}
                            fontSize={titleFontSize}
                            color={titleFontColor}
                            align={"center"}
                            maxWidth={textColumnMaxWidth}
                            colorScheme="primary"
                            as={"span"}>
                            Win
                        </Text>
                        <Text
                            fontWeight={"bold"}
                            fontSize={titleFontSize}
                            color={titleFontColor}
                            align={"center"}
                            maxWidth={textColumnMaxWidth}
                            colorScheme="primary"
                            as={"span"}>
                            {" "} Prizes!
                        </Text>
                    </Box>
                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"70%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        The Main Challenge boasts a R200k prize pool!
                    </Text>
                    <Box>
                        <Image
                            h={"80vh"}
                            src={prizes}
                        />
                    </Box>
                </Square>
            </Flex>

            <Modal isOpen={isOpen} onClose={onClose} size={"6xl"}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader/>
                    <ModalCloseButton/>
                    <ModalBody>
                        <AspectRatio
                            mt={2}
                            borderRadius={"0.5em"}
                            transform={"translateZ(0px)"}
                            ratio={16 / 9}
                            zIndex={0}>
                            <iframe
                                src={youtubeVideoMap.get("youtubeUrl")}
                                style={{
                                    borderRadius: "0.5em",
                                    border: "0"
                                }}
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen>
                            </iframe>
                        </AspectRatio>
                    </ModalBody>
                    <ModalFooter/>
                </ModalContent>
            </Modal>
        </Box>
    );
};
