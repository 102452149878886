const teamImages = {
    // Cloud
    Neil_Foxcroft: require("./Neil_Foxcroft.jpg").default,
    Francois_Volschenk: require("./Francois_Volschenk.jpg").default,
    Husnaa_Molvi: require("./Husnaa_Molvi.jpg").default,

    // Game Engine
    Natasha_Draper: require("./Natasha_Draper.jpg").default,
    Dewald_Swanepoel: require("./Dewald_Swanepoel.jpg").default,
    Kenwood_Nyirenda: require("./Kenwood_Nyirenda.jpg").default,
    Sameer_Otham: require("./Sameer_Otham.png").default,
    IB_van_Schalkwyk: require("./IB_van_Schalkwyk.jpg").default,
    Darshan_Dhayaram: require("./Darshan_Dhayaram.jpg").default,

    // Hackathon
    Simeon_Boshoff: require("./Simeon_Boshoff.jpg").default,
    Douglas_van_Reeuwyk: require("./Douglas_van_Reeuwyk.jpg").default,

    // Marketing
    Cailin_Perrie: require("./Cailin_Perrie.jpg").default,
    Kinesh_Pillay: require("./Kinesh_Pillay.jpg").default,

    // QA & UI/UX
    Kumiren_Naidoo: require("./Kumiren_Naidoo.png").default,
    Gabi_Joubert: require("./Gabi_Joubert.jpg").default,

    // Project Management & Administration
    Bianca_McFadyen: require("./Bianca_McFadyen.jpg").default,

    // Website
    Lijani_Van_Wyk_de_Vries: require("./Lijani_van_Wyk_de_Vries.jpg").default,
    Francois_Greeff: require("./Francois_Greeff.jpg").default,
    Sibusiso_Dlamini: require("./Sibusiso_Dlamini.jpg").default,
    Jessica_Cordier: require("./Jessica_Cordier.jpg").default,

};
export default teamImages;
