import {Box, Divider, Flex, Image, Link, ListItem, Spacer, Square, Text, UnorderedList, useBreakpointValue, useTheme} from "@chakra-ui/react";
import React from "react";
import image_right from "../../../assets/images/the-game/the_game_right.png";
import middleImage from "../../../assets/images/the-game/game_rules_middle_banner.png";
import goldenTicket from "../../../assets/images/the-game/golden-ticket.png";
import please_check_out from "../../../assets/images/the-game/please_check_out.png";
import background from "../../../assets/images/main/main_background.png";
import {getColorDefinitions} from "../../../shared/styling/colorDefinitions";

interface IUnauthenticatedGame {
}

export const UnauthenticatedGame: React.FunctionComponent<IUnauthenticatedGame> = _ => {
    let textColumnMaxWidth = "96em";
    let titleFontSize = 45;
    let minViewH = "50vh";
    let otherTextFontSize = "calc(1.2em + 1vmin)";

    const theme = useTheme();
    const {
        titleFontColor, mainFontColor, subTitleFontColor,
        accentColor, linkColor, onLinkHover
    } = getColorDefinitions(theme);

    let gameIconSize = "10em";

    const isMobile = useBreakpointValue({base: true, sm: true, md: true, lg: false});
    const entelectChallengeForumLink = process.env.REACT_APP_ENTELECT_CHALLENGE_FORUM;

    return (
        <Box
            background={`url(${background})`}
            backgroundSize={'cover'}
            backgroundRepeat={'no-repeat'}
            backgroundPosition={"center"}
            as="main">
            <Flex flexDirection={"column"} paddingTop={'5em'} paddingBottom={'5em'}>

                <Square
                    p={5} shadow="md" borderWidth="0.1em"
                    background={"whiteAlpha.800"}
                    borderRadius={"0.5em"}
                    mt={25}
                    width={"80%"}
                    pb={5}
                    maxWidth={textColumnMaxWidth}
                    minH={minViewH}
                    alignSelf={"center"}
                    flexDirection={"column"}>
                    <Box
                        align={"center"}
                        py={5}
                        width={"80%"}>
                        <Text
                            fontWeight={"bold"}
                            fontSize={titleFontSize}
                            color={titleFontColor}
                            align={"center"}
                            maxWidth={textColumnMaxWidth}
                            colorScheme="primary"
                            as={"span"}>
                            The Game
                        </Text>
                    </Box>
                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        Welcome to the 2025 Entelect Challenge!🌿🦁
                    </Text>
                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        This year, it's all about outsmarting the zookeeper to make your grand escape from the zoo and return to the wild! Inspired by the classic ‘Pac-Man', this game is packed with thrilling twists and turns.
                    </Text>
                    <Divider colorScheme={accentColor}/>
                    <Text
                        fontSize={otherTextFontSize}
                        color={subTitleFontColor}
                        fontWeight={"bold"}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}>
                        Meet the star of the 2025 Entelect Challenge:
                        <br/>
                    </Text>
                    <Text
                        fontSize={titleFontSize}
                        color={accentColor}
                        width={"80%"}
                        fontWeight={"bold"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        Zooscape
                    </Text>

                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        my={5}
                        textAlign={"center"}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        Zooscape is a multiplayer strategy game where your goal is to outwit the zookeeper and navigate your way through the zoo to freedom. As you play, you'll encounter various obstacles and power-ups that will either aid
                        or hinder your escape.

                        <br/>
                        <br/>
                        Each round presents new challenges and opportunities, making every game unique and exciting.

                        <br/>
                        <br/>

                        To win, you'll need to code a bot that can cleverly navigate the zoo, collect food and outmaneuver the zookeeper. Master the game mechanics, strategize effectively, and adapt to the ever-changing environment to lead
                        your bot to victory. Get ready for an exhilarating adventure filled with cunning strategies and daring escapes!
                    </Text>

                </Square>

                {!isMobile &&
                    <Square

                        width={"100%"}
                        maxWidth={textColumnMaxWidth}
                        alignSelf={"center"}
                        flexDirection={"column"}
                    >
                        <Image
                            marginTop={'5em'}
                            marginBottom={'5em'}
                            pos="inherit"
                            width={"100%"}
                            height={"40em"}
                            objectFit={"cover"}
                            src={middleImage}
                        />
                    </Square>
                }

                <Square
                    py={"5em"}
                    mt={isMobile ? 5 : 0}
                    shadow="md" borderWidth="0.1em"
                    background={"whiteAlpha.800"}
                    borderRadius={"0.5em"}
                    width={"80%"}
                    maxWidth={textColumnMaxWidth}
                    minH={"20vh"}
                    alignSelf={"center"}
                    flexDirection={"column"}>
                    <Flex direction={"column"} width={"80%"}>
                        <Text
                            fontSize={otherTextFontSize}
                            color={mainFontColor}
                            align={"left"}
                            fontWeight={"bold"}
                            colorScheme="primary"
                            pb={5}>
                            A <Link color={linkColor}
                                    textDecoration={'underline'}
                                    _hover={onLinkHover}
                                    href="https://github.com/EntelectChallenge/2025-Zooscape" isExternal>starter
                            pack</Link> will be available
                            to all players and includes:
                        </Text>
                        <UnorderedList align={"left"} fontSize={otherTextFontSize} color={mainFontColor}>
                            <ListItem>Game rules</ListItem>
                            <ListItem>A readme file on how to interact with the game engine</ListItem>
                            <ListItem>Sample bots to use as reference for different programming languages</ListItem>
                            <ListItem>Access to an {" "}
                                <Link
                                    color={linkColor}
                                    _hover={onLinkHover}
                                    href={entelectChallengeForumLink}
                                    isExternal
                                    textDecoration={'underline'}
                                >
                                    active forum
                                </Link>
                                {" "} for guidance
                            </ListItem>
                        </UnorderedList>
                    </Flex>
                </Square>

                {/* TODO: Add an icon on the LEFT  */}
                {!isMobile &&
                    <Square
                        width={"20%"}
                        maxWidth={textColumnMaxWidth}
                        h={"0vh"}
                        alignSelf={"center"}
                        flexDirection={"column"}>
                        <Image
                            w={"80vw"}
                            objectFit={"contain"}
                            pos="relative" top="-2vh" left="36vw"
                            fit={"contain"}
                            transform={"scaleX(-1)"}
                            h={"20em"}
                            src={image_right}
                        />
                    </Square>
                }

                <Square
                    p={isMobile ? "1em" : "5em"} shadow="md" borderWidth="0.1em"
                    background={"whiteAlpha.800"}
                    borderRadius={"0.5em"}
                    m={5}
                    mt={isMobile ? 5 : 20}
                    width={"80%"}
                    maxWidth={textColumnMaxWidth}
                    minH={minViewH}
                    alignSelf={"center"}
                    flexDirection={"column"}>

                    <Flex width={"100%"} alignItems={"center"} direction={isMobile ? "column" : "row"}>
                        <Text
                            fontSize={titleFontSize}
                            color={subTitleFontColor}
                            fontWeight={"bold"}
                            colorScheme="primary"
                            textAlign={"center"}
                            width={isMobile ? "95%" : "33%"}>
                            The Entelect Challenge
                        </Text>
                        <Spacer/>
                        <Image src={goldenTicket} boxSize={gameIconSize}/>
                        <Spacer/>
                        <Text
                            fontSize={titleFontSize}
                            color={subTitleFontColor}
                            fontWeight={"bold"}
                            colorScheme="primary"
                            width={isMobile ? "95%" : "33%"}
                            textAlign={"center"}>
                            2 Tournaments <br/>
                            8 Golden tickets
                        </Text>
                    </Flex>

                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        pt={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        The structure of the Main Challenge will remain the same as the previous year.
                        We’ll have 2 qualifying tournaments before the finals at Comic Con Africa!
                        The top 4 in each of these tournaments will get a golden ticket
                        - automatically putting them through to the finals. <br/><br/>
                        After tournament 1, we’ll introduce friendly matches where you can
                        measure your bot against other players’ in a relaxed environment.
                        These “friendlies” will provide you with the opportunity to improve your bot
                        against real participants without counting towards any score.<br/><br/>
                    </Text>
                    <Divider/>
                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        width={"80%"}
                        fontWeight={"bold"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        * Please check out *
                    </Text>
                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        <Link color={linkColor} _hover={onLinkHover} textDecoration={'underline'} href="https://github.com/EntelectChallenge/2025-Zooscape">Zooscape Github repository</Link> <br/>
                        <Link color={linkColor} _hover={onLinkHover} textDecoration={'underline'} href={entelectChallengeForumLink}>New Entelect Challenge structure</Link>
                    </Text>
                </Square>

                <Square
                    width={"65%"}
                    maxWidth={textColumnMaxWidth}
                    h={"0vh"}
                    alignSelf={"center"}
                    flexDirection={"column"}>
                    <Image
                        h={"15em"}
                        objectFit={"contain"}
                        pos="relative" top="-6vw" left="-50%"
                        fit={"contain"}
                        src={please_check_out}
                    />
                </Square>

            </Flex>
        </Box>
    );
};
