import { Box } from '@chakra-ui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Interceptors } from './api/axios.interceptor';
import { SettingsApi } from './api/settings.api';
import { cookieAtom } from './atoms/cookie.atom';
import { settingsAtom } from './atoms/settings.atom';
import { PasswordReset } from './pages/authentication/PasswordReset';
import { PostLogin } from './pages/authentication/PostLogin';
import { Register } from './pages/authentication/Register';
import { SignIn } from './pages/authentication/SignIn';
import { VerifyEmail } from './pages/authentication/VerifyEmail';
import { CookieBanner } from './pages/cookie/CookieBanner';
import { HackathonLeaderboards } from './pages/hackathon/hackathon-leaderboard/HackathonLeaderboards';
import { Navigation } from './pages/navigation/Navigation';
import { IntermediateGlobalLoading } from './shared/loading/IntermediateGlobalLoading';
import { Helmet } from 'react-helmet-async';
import { GoogleAnalyticsPageView } from './shared/loading/GoogleAnalyticsPageView';

export const App = () => {
    const history = useHistory();
    // Set token upon application open
    Interceptors.setupTokenInterceptor();
    Interceptors.setupUnauthorizedInterceptor(history);

    const cookiePreferences = useRecoilValue(cookieAtom);

    const location = useLocation();

    if (cookiePreferences.nonNecessary) {
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE);
        ReactGA.send({ hitType: 'pageview', page: location.pathname });
    }

    const setSettings = useSetRecoilState(settingsAtom);
    const [loading, setLoading] = useState(false);

    // Load settings
    useEffect(() => {
        setLoading(true);

        SettingsApi.getWebsiteSettings()
            .then((result) => {
                setSettings(result.data);
                setLoading(false);
            })
            .catch();
    }, [setSettings]);

    const getBaseUrl = (url: string): string => {
        const urlParts = url.split('/');
        urlParts.length = urlParts.length - 1;
        return urlParts.join('/');
    };

    const s3Endpoint = 'https://*.s3.eu-west-1.amazonaws.com';
    const apiEndpoint = getBaseUrl(process.env.REACT_APP_API_URL);
    const authEndpoint = getBaseUrl(process.env.REACT_APP_AUTHORIZATION_URL);
    const apiGWEndpoint = getBaseUrl(process.env.REACT_APP_TOURNAMENT_BASE_URL);

    const policyContent = `
      default-src 'self' https://www.youtube.com;
      font-src 'self' https://fonts.gstatic.com https://fonts.googleapis.com;
      script-src 'self' https://www.google-analytics.com https://www.googletagmanager.com;
      style-src 'self' 'unsafe-inline';
      img-src 'self' ${s3Endpoint} http://www.gravatar.com data:;
      media-src 'self' ${s3Endpoint};
      connect-src 'self' ${apiEndpoint} ${authEndpoint} ${s3Endpoint} ${apiGWEndpoint} https://www.google-analytics.com https://stats.g.doubleclick.net;
      manifest-src 'self' ${authEndpoint};
    `;

    return (
        <Box h={'100vh'}>
            <Helmet>
                <meta http-equiv={'Content-Security-Policy'} content={policyContent} />
            </Helmet>
            <GoogleAnalyticsPageView />
            {loading ? (
                <IntermediateGlobalLoading />
            ) : (
                <Switch>
                    <Route path={'/signin'}>
                        <SignIn />
                    </Route>
                    <Route path={'/email-verification'}>
                        <VerifyEmail />
                    </Route>
                    <Route path={'/register'}>
                        <Register />
                    </Route>
                    <Route path={'/password-reset'}>
                        <PasswordReset />
                    </Route>
                    <Route path={'/postLogin'}>
                        <PostLogin />
                    </Route>
                    <Route exact path={'/'}>
                        <Redirect to={'/home'} />
                    </Route>
                    <Route path={['/portal', '/home', '/getting-started', '/cicd-download', '/legacy/:year', '/legacy', '/wiki', '/coming-soon', '/team', '/university', '/tournaments', '/game', '/notifications', '/home', '/fun']}>
                        <Navigation />
                    </Route>
                    <Route path={'/public/leaderboard/hackathon/:type'}>
                        <HackathonLeaderboards isPublic={true} />
                    </Route>
                </Switch>
            )}
            <CookieBanner />
        </Box>
    );
};
