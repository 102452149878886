import { HistoryItem } from "../../../models/history-item.model";

export const challengeGamesHistory: HistoryItem[] = [
    {
        title: "Sproutopia",
        year: `'24`,
        videoUrl: "https://www.youtube.com/embed/2ia7_uMDhM0?si=EzNcxi7NfQtsCWqM",
        summary: `In 2024 our game was Sproutopia, a multiplayer land gathering game that blossomed from the essence of Land-io. Your aim was to outshine fellow players by swiftly cultivating land to claim it as your own, whilst fending off weeds and outmaneuvering your rivals.`,
        fullYear: 2024
    },
    {
        title: "Cy-Fi",
        year: `'23`,
        videoUrl: "https://www.youtube.com/embed/izwaqFisTvs",
        summary: `In 2023 our game was Cy-Fi. An 8-bit, side-scrolling, speed runner that's reminiscent of Super Mario Bros. The objective was to be the fastest player to collect enough items to advance to the next level.`,
        fullYear: 2023
    },
    {
        title: "Arctica",
        year: `'22`,
        videoUrl: "https://www.youtube.com/embed/tKZC9HRVpIA",
        summary: `In 2022 our game was Arctica. A real-time strategy (RTS) style game where Challengers required balancing town's resources and growing the population in the frozen tundra.`,
        fullYear: 2022
    },
    {
        title: "Galaxio",
        year: `'21`,
        videoUrl: "https://www.youtube.com/embed/hnYIlOk5QxM",
        summary: "In 2021 our game was Galaxio, based on a theme from Agar.io, which lends itself to a vast multiplayer universe – a perfect fit for the 10th anniversary of the Entelect challenge.",
        fullYear: 2021
    },
    {
        title: "Overdrive",
        year: `'20`,
        videoUrl: "https://www.youtube.com/embed/BXWoGjYkZmY",
        summary: "In 2020 the game was Overdrive. The Challenge brought together novice coders and experienced professionals, as they competed against one another to build a bot that would race to cross the finish line first in a Knight-rider themed racing game.",
        fullYear: 2020
    },
    {
        title: "Worms",
        year: `'19`,
        videoUrl: "https://www.youtube.com/embed/P61Ulao0btg",
        summary: "In 2019 the game was Worms. Players developed intelligent bots to battle against each other in a space themed Worm game. Players had to manoeuvre their bots around obstacles of fire to defeat their enemy and emerge victorious, while avoiding dance battle-esque standoffs.",
        fullYear: 2019
    },
    {
        title: "Tower Defence",
        year: `'18`,
        videoUrl: "https://www.youtube.com/embed/NRWJxwK7SW8",
        summary: "In 2018 the game was Tower Defence. Players developed intelligent bots to play our Command and Conquer: Red Alert themed, Plants vs Zombies, tower defence game. Players had to manage their resources as well as defend their base while trying to attack their opponent.",
        fullYear: 2018
    },
    {
        title: "Battleships",
        year: `'17`,
        videoUrl: "https://www.youtube.com/embed/wN-V7GufoMw",
        summary: "In 2017 the game was Battleships. Players developed intelligent bots to play the classic game and destroy their opponent's ships. Twists in the game were added where different weapon choices and ships became available to enable different strategies.",
        fullYear: 2017
    },
    {
        title: "Bomberman",
        year: `'16`,
        videoUrl: "https://www.youtube.com/embed/Ir5eNssOT1w",
        summary: "In 2016 the game was Bomberman. Players developed intelligent bots that needed to navigate a map and blow their opponents up. This was the first game where four players competed in a single match at the same time. The game was made interesting with hidden power-ups.",
        fullYear: 2016
    },
    {
        title: "Space Invaders",
        year: `'15`,
        videoUrl: "https://www.youtube.com/embed/m9JGsCu7ohg",
        summary: "In 2015 the game was Space Invaders. Players developed intelligent bots that controlled a space ship that needed to defend it's based from its opponent and incoming hordes of aliens by shooting them down. Players could build power-ups that provided different advantages for different strategies.",
        fullYear: 2015
    },
    {
        title: "Pacman",
        year: `'14`,
        videoUrl: "https://www.youtube.com/embed/NHArVk-4tIk",
        summary: "In 2014 the game was Pac Man. Players developed intelligent bots that controlled their very own Pac Man bot. The goal was to collect as many pills as possible and gunning for the bonus pills on the map. A twist was added where players could teleport around the map.",
        fullYear: 2014
    },
    {
        title: "Battle City",
        year: `'13`,
        videoUrl: "https://www.youtube.com/embed/a3zDz9NZYjE",
        summary: "In 2013 the game was Battle City. Players developer intelligent bots that controlled two tanks where the goal was to destroy the enemy base. This was made difficult since players had to calculate optimal paths to the base by destroying or avoiding walls.",
        fullYear: 2013
    },
    {
        title: "Tron",
        year: `'12`,
        videoUrl: "https://www.youtube.com/embed/iHlhMoPp0Wc",
        summary: "The first year of the Entelect Challenge was dubbed the R100K Challenge. This year challenged contestants to develop an intelligent bot to play a snake-like game on a sphere where the goal was to cover as much ground whilst trying to cut its opponent off.",
        fullYear: 2012
    }
];
