import { ArrowForwardIcon, EditIcon, InfoOutlineIcon, NotAllowedIcon } from "@chakra-ui/icons";
import {Box, Button, Center, Flex, HStack, Image, Spacer, Spinner, Stack, Text, Tooltip, useTheme, VStack} from "@chakra-ui/react";
import moment from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import { HackathonApi } from "../../api/hackathon.api";
import cardBackground from "../../assets/images/hackathon/hackathon_card_background.png";
import { useScaleRationBoolean } from "../../utils/hooks/use-scale-ratio";
import { CountDown } from "./CountDown";
import {getColorDefinitions} from "../../shared/styling/colorDefinitions";

interface IHackathonHomeCard {
    name: string;
    mode: string;
    date: Date | undefined;
    onRegister: Function;
    onRules: Function;
    maxCount: number;
    type: string;
    isAuthorized: boolean;
    haveEntry: boolean;
    isOpen: boolean;
}

interface ILogoHackathonHomeCard extends IHackathonHomeCard {
    logo: string;
}

export const LogoHackathonHomeCard: React.FunctionComponent<ILogoHackathonHomeCard> = props => {

    const isZoomed = useScaleRationBoolean([false, true]);
    const [teamCount, setTeamCount] = useState(0);
    const [teamCountLoading, setTeamCountLoading] = useState(false);

    const theme = useTheme();
    const { primaryButtonColor,
        secondaryButtonColor } = getColorDefinitions(theme);

    const darkBackgroundAlternativeProps = {
        color: 'white'
    }


    // Load player
    useEffect(() => {
        setTeamCountLoading(true);
        HackathonApi
            .getTeamCount(props.type)
            .then(result => {
                setTeamCount(result.data);
                setTeamCountLoading(false);
            })
            .catch(() => setTeamCountLoading(false));
    }, [props.type]);

    const renderHackathonButton = () => {
        if (!props.isAuthorized) {
            return (
                <Button
                    leftIcon={<NotAllowedIcon/>}
                    colorScheme={"navigation"}
                    backgroundColor={primaryButtonColor}
                    px={6}
                    disabled
                    w={"100%"}
                >
                    Access denied
                </Button>
            );
        }

        if (!props.isOpen) {
            return (
                <Button
                    leftIcon={<NotAllowedIcon/>}
                    colorScheme={"navigation"}
                    backgroundColor={primaryButtonColor}
                    px={6}
                    disabled
                    w={"100%"}
                >
                    Closed
                </Button>
            );
        }

        return (
            <Button
                leftIcon={props.haveEntry ? <ArrowForwardIcon/> : <EditIcon/>}
                colorScheme={"navigation"}
                backgroundColor={primaryButtonColor}
                px={6}
                onClick={() => props.onRegister()}
                w={"100%"}
            >
                {props.haveEntry ? "Enter" : "Register"}
            </Button>
        );
    };

    return (
        <Box
            w={"100%"}
            background={`linear-gradient(to right, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6) ), url(${cardBackground})`}
            backgroundSize={"cover"}
            borderRadius={"lg"}
            p={1.5}>

            <Box
                height={"100%"}
                borderRadius={"lg"}
                borderWidth={"1px"}
                borderColor={"navigation.400"}
                _hover={{
                    cursor: "default"
                }}
                flexDirection={"row"}
                p={5}>
                <Flex direction={"column"} justifyContent={"space-between"} height={"100%"}>
                    <Flex alignItems={"start"}>
                        <Image
                            alignSelf={"center"}
                            objectFit="cover"
                            w={"45%"}
                            src={props.logo}
                            alt="Hackathon card logo"
                        />
                        <Spacer/>
                        <VStack align={"end"}>
                            <Text
                                color={"whiteAlpha.900"}
                                fontSize={"lg"}>
                                {props.date ? moment(props.date)
                                    .format("YYYY.MM.DD") : "TBA"}
                            </Text>

                            <Text
                                color={"whiteAlpha.900"}
                                fontWeight={"bold"}
                                letterSpacing={1.2}
                                textAlign={"right"}
                                fontSize={"2xl"}
                                pb={1}>
                                {props.name}
                            </Text>

                            {
                                !isZoomed &&
                                <Text color={"whiteAlpha.900"} fontSize={"lg"} fontWeight={"semibold"}>{props.mode}</Text>
                            }

                            {props.date &&
                                <Box mt={4}>
                                    <CountDown date={props.date} alternativeProps={darkBackgroundAlternativeProps}/>
                                </Box>
                            }
                        </VStack>
                    </Flex>
                    <Stack direction={["column", "column", "row", "row"]} mt={6} spacing={2}>
                        <Box>
                            <Stack direction={["column", "column", "row", "row"]} spacing={4}>
                                {
                                    renderHackathonButton()
                                }
                                <Button
                                    w={"100%"}
                                    leftIcon={<InfoOutlineIcon/>}
                                    colorScheme={"primary"}
                                    backgroundColor={secondaryButtonColor}
                                    px={6}
                                    onClick={() => props.onRules()}>
                                    Rules
                                </Button>
                            </Stack>
                        </Box>
                        <Spacer/>
                        <Box
                            borderRadius={"md"}
                            borderWidth={"1px"}
                            borderColor={"navigation.400"}
                            backgroundColor={"navigation.500"}
                            px={7}
                            py={1}>
                            <Center h={"100%"}>
                                {
                                    teamCountLoading
                                        ? <Spinner color={"whiteAlpha.900"}/>
                                        : <Tooltip
                                            hasArrow
                                            label="Total Team Count / Total Team Capacity"
                                            bg="accent.100"
                                            color="black">
                                            <Text
                                                color={"whiteAlpha.900"}
                                                fontWeight={"semibold"}
                                                fontSize={"18px"}
                                                letterSpacing={1.2}>
                                                {teamCount}/{props.maxCount}
                                            </Text>
                                        </Tooltip>
                                }

                            </Center>
                        </Box>
                    </Stack>
                </Flex>
            </Box>

        </Box>
    );
};

export const HackathonHomeCard: React.FunctionComponent<IHackathonHomeCard> = props => {

    const isZoomed = useScaleRationBoolean([false, true]);
    const [teamCount, setTeamCount] = useState(0);
    const [teamCountLoading, setTeamCountLoading] = useState(false);

    // Load player
    useEffect(() => {
        setTeamCountLoading(true);
        HackathonApi.getTeamCount(props.type)
            .then(result => {
                setTeamCount(result.data);
                setTeamCountLoading(false);
            })
            .catch(() => setTeamCountLoading(false));
    }, [props.type]);


    const lightBackgroundAlternativeProps = {
        backgroundColor: '#ffffff70',
        color: 'red'
    }

    const renderHackathonButton = () => {
        if (!props.isAuthorized) {
            return (
                <Button
                    leftIcon={<NotAllowedIcon/>}
                    colorScheme={"navigation"}
                    px={6}
                    disabled
                    w={"100%"}
                >
                    Access denied
                </Button>
            );
        }

        if (!props.isOpen) {
            return (
                <Button
                    leftIcon={<NotAllowedIcon/>}
                    colorScheme={"navigation"}
                    backgroundColor={"primary.900"}
                    px={6}
                    disabled
                    w={"100%"}
                >
                    Closed
                </Button>
            );
        }

        return (
            <Button
                leftIcon={props.haveEntry ? <ArrowForwardIcon/> : <EditIcon/>}
                colorScheme={"navigation"}
                backgroundColor={"navigation.500"}
                px={6}
                onClick={() => props.onRegister()}
                w={"100%"}
            >
                {props.haveEntry ? "Enter" : "Register"}
            </Button>
        );
    };

    return (
        <Box
            background={`linear-gradient(to right, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6) ), url(${cardBackground})`}
            borderRadius={"lg"}
            p={1.5}
        >
            <Box
                borderRadius={"lg"}
                borderWidth={"1px"}
                borderColor={"navigation.400"}
                _hover={{
                    cursor: "default"
                }}
                p={5}
                w={"100%"}
                h={"100%"}
            >

                <VStack w={"100%"} h={"100%"}>
                    <Box w={"100%"}>
                        <HStack w={"100%"}>
                            <Text
                                color={"whiteAlpha.900"}
                                fontWeight={"bold"}
                                letterSpacing={1.2}
                                fontSize={"2xl"}>
                                {props.name}
                            </Text>
                            <Spacer/>
                            <Text
                                color={"whiteAlpha.900"}

                                fontSize={"lg"}>
                                {props.date ? moment(props.date)
                                    .format("YYYY.MM.DD") : "TBA"}
                            </Text>
                        </HStack>
                    </Box>

                    {
                        !isZoomed &&
                        <Box w={"100%"}>
                            <Text
                                color={"whiteAlpha.900"}
                                fontSize={"lg"}
                                fontWeight={"semibold"}>
                                {props.mode}
                            </Text>
                        </Box>

                    }
                    {
                        props.date &&
                        <Box mt={4}>
                            <CountDown date={props.date} alternativeProps={lightBackgroundAlternativeProps} />
                        </Box>
                    }

                    <Spacer/>

                    <Box w={"100%"}>
                        <Stack direction={["column", "column", "row", "row"]} mt={6} spacing={2}>
                            <Box>
                                <Stack direction={["column", "column", "row", "row"]} spacing={4}>
                                    {
                                        renderHackathonButton()
                                    }
                                    <Button
                                        w={"100%"}
                                        leftIcon={<InfoOutlineIcon/>}
                                        colorScheme={"accent"}
                                        variant={"outline"}
                                        px={6}
                                        onClick={() => props.onRules()}>
                                        Rules
                                    </Button>
                                </Stack>
                            </Box>

                            <Spacer/>

                            <Box
                                borderRadius={"md"}
                                borderWidth={"1px"}
                                borderColor={"navigation.400"}
                                backgroundColor={"navigation.500"}
                                px={7}
                                py={1}>
                                <Center h={"100%"}>
                                    {
                                        teamCountLoading
                                            ? <Spinner color={"whiteAlpha.900"}/>
                                            : <Tooltip
                                                hasArrow
                                                label="Total Team Count / Total Team Capacity"
                                                bg="accent.100"
                                                color="black">
                                                <Text
                                                    color={"whiteAlpha.900"}
                                                    fontWeight={"semibold"}
                                                    fontSize={"18px"}
                                                    letterSpacing={1.2}>
                                                    {teamCount}/{props.maxCount}
                                                </Text>
                                            </Tooltip>
                                    }

                                </Center>
                            </Box>
                        </Stack>
                    </Box>
                </VStack>

            </Box>
        </Box>
    );
};
