import { FunctionComponent, ReactNode } from "react";
import {Box, Text, useTheme } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import {getColorDefinitions} from "../../shared/styling/colorDefinitions";

interface IWikiCategoryCard {
    name: string;
    path: string;
    onCustomClick?: () => void;
}

export const WikiCategoryCard: FunctionComponent<IWikiCategoryCard> = props => {

    const theme = useTheme();
    const { secondaryButtonColor, secondaryButtonColorHover } = getColorDefinitions(theme);

    const textFontSize = "calc(0.8em + 0.8vmin)";

    const hoverStyling = {
        boxShadow: `${secondaryButtonColorHover} 0px 3px 10px 0px`,
        transform: "translate3d(0px, -2px, 0px)",
        cursor: "pointer"
    };

    const displayCardWithCustomClick = (): ReactNode => {
        return (
            <Box
                borderColor={secondaryButtonColor}
                borderWidth={1}
                borderRadius={"md"}
                py={5}
                px={6}
                transition={"0.10s ease-in-out"}
                _hover={hoverStyling}
                onClick={props.onCustomClick}>
                <Text color={secondaryButtonColor} fontWeight={"bold"} fontSize={textFontSize}>
                    {
                        props.name
                    }
                </Text>
            </Box>
        );
    };

    const displayNormalCard = (): ReactNode => {
        return (
            <Link to={props.path}>
                <Box
                    borderColor={secondaryButtonColor}
                    borderWidth={1}
                    borderRadius={"md"}
                    py={5}
                    px={6}
                    transition={"0.10s ease-in-out"}
                    _hover={hoverStyling}>
                    <Text color={secondaryButtonColor} fontWeight={"bold"} fontSize={textFontSize}>
                        {
                            props.name
                        }
                    </Text>
                </Box>
            </Link>
        );
    };

    return (
        <>{props.onCustomClick ? displayCardWithCustomClick() : displayNormalCard()}</>
    );
};
