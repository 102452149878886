import { Box, Flex, Image, Text } from '@chakra-ui/react';
import React, { FC, useEffect, useRef } from 'react';
import { TeamMemberDetails } from '../../../assets/images/team/teamAssets';

interface ITeamSquadMembers {
    teamMembers: Array<TeamMemberDetails>;
    squadName: string;
}

export const TeamSquadMembers: FC<ITeamSquadMembers> = (props) => {
    const titleRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('title-animate');
                    }
                });
            },
            { threshold: 0.5 }
        );

        if (titleRef.current) {
            observer.observe(titleRef.current);
        }

        return () => observer.disconnect();
    }, []);

    return (
        <Box
            width="100%"
            py={16}
            position="relative"
            backgroundImage="linear-gradient(to bottom, rgba(0,0,0,0.3), transparent)"
        >
            {/* Enhanced Section Title */}
            <Box
                ref={titleRef}
                mb={16}
                textAlign="center"
                position="relative"
                className="title-wrapper"
                sx={{
                    '@keyframes titleGlow': {
                        '0%': {
                            opacity: 0,
                            transform: 'translateY(20px)',
                            textShadow: '0 0 0 rgba(205, 127, 50, 0)'
                        },
                        '100%': {
                            opacity: 1,
                            transform: 'translateY(0)',
                            textShadow: '0 0 30px rgba(205, 127, 50, 0.5)'
                        }
                    },
                    '@keyframes underlineGlow': {
                        '0%': {
                            width: '0',
                            opacity: 0
                        },
                        '100%': {
                            width: '150px',
                            opacity: 1
                        }
                    },
                    '&.title-animate': {
                        '& > h2': {
                            animation: 'titleGlow 1s ease-out forwards'
                        },
                        '&::after': {
                            animation: 'underlineGlow 0.8s ease-out 0.3s forwards'
                        }
                    },
                    '&::after': {
                        content: '""',
                        position: 'absolute',
                        bottom: '-15px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '0',
                        height: '4px',
                        background: 'linear-gradient(to right, transparent, #CD7F32, transparent)',
                        borderRadius: 'full',
                        opacity: 0
                    }
                }}
            >
                <Text
                    as="h2"
                    fontSize="5xl"
                    fontWeight="black"
                    color="white"
                    textTransform="uppercase"
                    letterSpacing="wider"
                    opacity="0"
                >
                    {props.squadName}
                </Text>
            </Box>

            {/* Centered Grid Container */}
            <Box
                maxWidth="1400px"
                mx="auto"
                px={8}
            >
                <Flex
                    flexWrap="wrap"
                    justifyContent="center"
                    gap={8}
                    sx={{
                        '& > *': {
                            flex: '0 0 280px',
                            maxWidth: '280px'
                        }
                    }}
                >
                    {props.teamMembers.map((member) => (
                        <Box
                            key={member.name}
                            width="280px"
                            height="360px"
                            position="relative"
                            borderRadius="xl"
                            overflow="hidden"
                            boxShadow="2xl"
                            background="linear-gradient(45deg, #8B4513, #A0522D)"
                            _before={{
                                content: '""',
                                position: 'absolute',
                                top: '4px',
                                left: '4px',
                                right: '4px',
                                bottom: '4px',
                                background: 'white',
                                borderRadius: 'lg',
                                zIndex: 1
                            }}
                        >
                            {/* Image Container */}
                            <Box
                                position="relative"
                                height="280px"
                                zIndex={2}
                                margin="8px"
                                borderRadius="lg"
                                overflow="hidden"
                                boxShadow="inset 0 0 10px rgba(0,0,0,0.2)"
                            >
                                <Image
                                    src={member.imageFile}
                                    alt={member.displayName}
                                    width="100%"
                                    height="100%"
                                    objectFit="cover"
                                    objectPosition="center top"
                                    sx={{
                                        '@supports (object-fit: cover)': {
                                            objectPosition: 'center top'
                                        }
                                    }}
                                />
                                <Box
                                    position="absolute"
                                    top={0}
                                    left={0}
                                    right={0}
                                    bottom={0}
                                    background="radial-gradient(circle at center, transparent 50%, rgba(0,0,0,0.2) 100%)"
                                />
                            </Box>

                            {/* Name Plate */}
                            <Box
                                position="absolute"
                                bottom={8}
                                left={8}
                                right={8}
                                zIndex={2}
                                background="linear-gradient(45deg, #8B4513, #A0522D)"
                                borderRadius="md"
                                p={3}
                                boxShadow="lg"
                            >
                                <Text
                                    color="white"
                                    fontSize="lg"
                                    fontWeight="bold"
                                    textAlign="center"
                                    textShadow="1px 1px 2px rgba(0,0,0,0.3)"
                                >
                                    {member.displayName}
                                </Text>
                            </Box>

                            {/* Corner Decorations */}
                            {['tl', 'tr', 'bl', 'br'].map((corner) => (
                                <Box
                                    key={corner}
                                    position="absolute"
                                    width="20px"
                                    height="20px"
                                    borderRadius="full"
                                    bg="#8B4513"
                                    border="2px solid #A0522D"
                                    zIndex={3}
                                    {...(corner.includes('t') ? {top: '12px'} : {bottom: '12px'})}
                                    {...(corner.includes('l') ? {left: '12px'} : {right: '12px'})}
                                />
                            ))}
                        </Box>
                    ))}
                </Flex>
            </Box>
        </Box>
    );
};
