import * as React from 'react';
import { Box, Center, Text } from "@chakra-ui/react";

interface ISingleDateBox {
    date: string,
    dateName: string;
    alternativeProps?: any;
}

export const SingleDateBox: React.FunctionComponent<ISingleDateBox> = props => {
    return (
        <Box>
            <Box
                px={6}
                py={2}
                background={'navigation.700'}
                borderRadius={'md'}
                color={'white'}
                fontSize={'xl'}
                fontWeight={'semibold'}
            >{props.date}</Box>
            <Center>
                <Text fontSize={'14px'}
                      color={'navigation.900'}
                      fontWeight={'semibold'} {...props.alternativeProps}>{props.dateName}</Text>
            </Center>
        </Box>

    )
};
