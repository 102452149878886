import * as React from 'react';
import {Box, ListItem, Text, UnorderedList} from "@chakra-ui/react";
import {WikiSection} from "../WikiSection";
import {WikiSubSection} from "../WikiSubSection";
import {WikiRegionContactDetails} from "../WikiRegionContactDetails";

const InformationOfficerContactDetails = [
    {
        id: 0,
        region: 'South Africa',
        informationOfficer: {name: 'Shashi Hansjee'},
        address: 'Unit 13, 3 Melrose Boulevard, Melrose Arch, Johannesburg, South Africa'
    },
    {
        id: 1,
        region: 'The Netherlands',
        informationOfficer: {name: 'Adrian Maritz', tel: '+31 20 225 4566'},
        address: 'Barbara Strozzilaan 101, 1083 HN Amsterdam',
    },
    {
        id: 2,
        region: 'New Zealand',
        informationOfficer: {name: 'Yatish Parshotam', tel: '+64 21 315 839'},
        address: 'Level 2, 275 Cuba Street, Te Aro, Wellington, New Zealand'
    },
    {
        id: 3,
        region: 'United Kingdom',
        informationOfficer: {name: 'Doug Crawford', tel: '+44 (0) 7867 846 991'},
        address: '39 Kings Chase, East Molesey, KT8 9DG, London, United Kingdom'
    },
    {
        id: 4,
        region: 'Australia',
        informationOfficer: {name: 'Jonathan Sher', tel: '+61 481 753 218'},
        address: 'Suite 706, Level 7, 171 Clarence Street, Sydney 2000, Australia'
    }
]


export const WikiPrivacyPolicySection: React.FunctionComponent = _ => {

    const renderInformationOfficerContactDetails = (regions: any[]) => {

        return (regions.map(region =>
            <WikiRegionContactDetails
                region={region.region}
                informationOfficer={region.informationOfficer}
                deputyInformationOfficer={region.deputyInformationOfficer}
                address={region.address}
                generalEmail={region.generalEmail}
                dataQueries={region.dataQueries}
            ></WikiRegionContactDetails>
        ));


    }
    return (
        <Box>
            <WikiSection heading={'Privacy Policy'}>
                <WikiSubSection heading={'1. Introduction'}>
                    <Text color={'black'}>
                        1.1. This is the Privacy Policy of Entelect Software (Pty) Ltd, registration number 2007/001837/07 (“Entelect”) and its subsidiaries (“Entelect Group”)
                        <br/><br/>
                        1.2. This policy details how we collect, store, protect and use personal information – that is information that can be used to identify an individual or (in South Africa, a corporate entity) – in connection with the
                        services we may offer through our websites and through your dealings with us (referred to as the “Services”).
                    </Text>
                </WikiSubSection>
                <WikiSubSection heading={"2. Who is Processing your information"}>
                    <Text color={'black'}>
                        2.1. Entelect Group acts both as a data controller/ responsible party and a data processor/ operator depending on the context.
                        <br/><br/>
                        2.2. As the Controller or Responsible Party
                        <br/>
                        Where you are dealing with us directly this Privacy Policy applies.
                        <br/><br/>
                        2.3. As the Processor or Operator
                        <br/><br/>
                        Where we have been contracted by another company, such as your employer or a company you are dealing with directly, please be aware that we are not the entity responsible for making decisions on your personal
                        information, but instead are providing services to the company that you are dealing with directly. Please refer to the privacy policy of the company you are dealing with in these cases.
                        <br/><br/>
                        2.4 This Privacy Policy aims to give you information on how Entelect Group collects and processes your personal information when you personally or for a corporate entity:
                        <br/><br/>
                        (1) visit this website or any other website that is owned or operated by us;
                        <br/><br/>
                        (2) use any of our services as a customer;
                        <br/><br/>
                        (3) engage with us on prospective contracts or other commercial arrangements with us or for other business purposes; and
                        <br/><br/>
                        (4) conclude a contract with us (for example, where you act as a customer, supplier, reseller, agent, etc).
                        <br/><br/>
                        2.5 Entelect operates within different countries, including South Africa, the United Kingdom, the Netherlands, New Zealand and Australia. This privacy policy is intended to operate at a global level, so when we refer
                        to “Entelect”, “we”, “us” or “our” in this privacy policy, the relevant Entelect entity in which country you are engaging with will be responsible for processing your information in respect of the circumstances to
                        which this privacy policy applies.
                        <br/><br/>
                        2.6 We have appointed data protection officers who are responsible for overseeing questions in relation to this privacy policy in your jurisdiction. If you have any questions about this privacy policy, including any
                        requests to exercise your legal rights, please contact the relevant data protection officer using the details set out below.
                        <br/><br/>

                        {renderInformationOfficerContactDetails(InformationOfficerContactDetails)}


                    </Text>
                </WikiSubSection>
                <WikiSubSection heading={'3. What is personal information?'}>
                    <Text color={'black'}>
                        3.1. We may collect, use, store and transfer different kinds of personal information which we have grouped together as follows:
                        <br/> <br/>
                        (1) <b>Identity Information</b> includes first name, maiden name, last name, username or similar identifier, marital status, title, date of birth, gender, company registration number, tax number, and may also include
                        due diligence (know-your-client) information.
                        <br/><br/>
                        (2) <b> Contact Information </b> includes billing address, delivery address, email address and telephone numbers.
                        <br/><br/>
                        (3) <b> Financial Information </b> includes bank account information.
                        <br/><br/>
                        (4) <b> Transaction Information </b> includes details about payments to and from you and other details of products and services you have purchased from us, and includes details about invoicing and other payment
                        arrangements that may apply to our relationship with you.
                        <br/><br/>
                        (5) <b> Profile Information </b> includes your contact information together with, purchases or orders made by you, your interests, preferences, feedback and survey responses.
                        <br/><br/>
                        (6) <b> Marketing and Communications Information </b> includes your preferences in receiving marketing from us and our third parties and your communication preferences.

                        <br/> <br/>
                        3.2. We do not collect any special personal information (sensitive personal data) about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation,
                        political opinions, trade union membership, information about your health, and genetic and biometric information). Nor do we collect any information about criminal convictions and offences unless you are applying for
                        employment with us.
                        <br/> <br/>
                        3.3. We may collect this personal information in various ways such as directly from you, from correspondence with you, or through meetings or other assessments; or from third parties
                        <br/> <br/>
                        3.4. We will only process your personal information when we have a legitimate basis to do so.

                        <br/> <br/>
                        (a) Electronic communications, such as subscribing to our mailing list.
                        <br/> <br/>
                        (b) Where we are required to process any sensitive or special personal information where consent is required as a matter of law.
                        <br/> <br/>
                        (c) Cookies, in order to grant you a personalised experience when dealing with us. You may decline the installation of cookies; however, this may affect the functionality of our website or prevent you from using the
                        Site entirely. If you opt out of using cookies, we may store a single cookie in order to remember this preference.
                        <br/> <br/>
                        (d) For website analytics, storing details such as your IP address, bounce rate, page time, cost per click, most visited pages, and device information. These tracking cookies may be declined at any time.
                        <br/> <br/>
                        (e) For promotional campaigns, in which case additional information may be volunteered by you in order to participate in the promotion or to receive a delivery.
                    </Text>
                </WikiSubSection>
                <WikiSubSection heading={'4. Where is your information being processed'}>
                    <Text color={'black'}>
                        4.1. Our primary region for storing and processing data is South Africa.
                        <br/>
                        <br/>
                        4.2. In some cases, your information may be stored and processed outside of the country or region where it was originally collected. In some of these countries, you may have fewer rights in respect of your
                        information than you do in your country of residence.
                        <br/>
                        <br/>
                        4.3. We ensure your personal information is protected by requiring all our group companies to follow the same rules when processing your personal information.
                    </Text>
                </WikiSubSection>
                <WikiSubSection heading={'5. Who else will have access to your personal information'}>
                    <Text color={'black'}>
                        There are various ways in which we may process your data. This section details our purposes for processing, our legal basis for the processing, and our storage periods
                        <br/>
                        <br/>
                        In certain cases, we will process your data after obtaining your consent. You are free to withdraw your consent at any time. We may process your data with your consent for the following purposes:
                        <UnorderedList color={'black'}>
                            <ListItem>Electronic communications, such as subscribing to our mailing list or by contacting us via a form or email address upon the Site.</ListItem>
                            <ListItem>Cookies, in order to grant you a personalised experience when dealing with us. You may decline the installation of cookies; however, this may affect the functionality of our website or prevent you from
                                using the Site entirely. If you opt out of using cookies, we may store a single cookie in order to remember this preference.</ListItem>
                            <ListItem>For website analytics, storing details such as your IP address, bounce rate, page time, cost per click, most visited pages, and device information. These tracking cookies may be declined at any
                                time.</ListItem>
                            <ListItem>For promotional campaigns, in which case additional information may be volunteered by you in order to participate in the promotion or to receive a delivery.</ListItem>
                        </UnorderedList>
                        <br/>
                        We will process your data when we have to <b>perform a contract</b>; for as long as the contractual relationship is in place, and for any additional duration specified by the contract or legal requirement. In order
                        to fulfil our obligations to you in terms of the Services you have engaged us for, we will need to process your data.
                        <br/>
                        <br/>
                        We will process your data when we are <b>required by law to do so</b>; such as complying with an obligation imposed on us, handling claims, complying with regulation, or pursuing good governance.
                        <br/>
                        <br/>
                        We may also process your data in terms of <b>legitimate interest</b>, as long as the data being processed is strictly necessary, proportional, and does not infringe on your individual rights to privacy. Processing
                        may take place in terms of the following necessary scenarios:
                        <UnorderedList color={'black'}>
                            <ListItem>To monitor and analyse how to improve our Service and/or our Site, as well as to keep the same secure and free from abuse. This may include communications such as surveys or direct electronic
                                communications.</ListItem>
                            <ListItem>To keep active communications with you while you are our contracted client. Certain communications are necessary during delivery of our Services, and we have a legitimate interest in keeping you
                                information about our Services.</ListItem>
                        </UnorderedList>
                    </Text>
                </WikiSubSection>
                <WikiSubSection heading={'6. How are we processing your data?'}>
                    <Text color={'black'}>
                        6.1. We will only process personal information as a result of the provision of our Services, or in operating our Site and in accordance with this privacy policy.
                        <br/>
                        <br/>
                        6.2. We will ensure that our employees are subject to confidentiality agreements and any statutory privacy obligations.
                        <br/>
                        <br/>
                        6.3. We implement appropriate technical and organisational measures to ensure a level of security appropriate to the risk.
                    </Text>
                </WikiSubSection>

                <WikiSubSection heading={'7. Marketing (if applicable to our relationship with you)'}>
                    <Text color={'black'}>
                        7.1. We strive to provide you with choices regarding certain personal information uses, particularly around marketing and advertising.
                        <br/>
                        <br/>
                        7.2. We may use your Identity, Contact, Technical, Usage and Profile Information to form a view on what we think you may want or need, or what may be of interest to you. This is how we decide which products, services
                        and offers may be relevant for you (we call this marketing).
                        <br/>
                        <br/>
                        7.3 You will receive marketing communications from us if you have requested information from us or purchased Services from us and you have not opted out of receiving that marketing.
                        <br/>
                        <br/>
                        7.4 You can ask us or third parties to stop sending you marketing messages at any time by following the opt-out links on any marketing message sent to you OR by contacting us at any time.
                        <br/>
                        <br/>
                        7.5. Where you opt out of receiving these marketing messages, this will not apply to personal information provided to us that is necessary for the purposes of our contract, relationship, legal obligation or
                        legitimate interest.
                    </Text>
                </WikiSubSection>
                <WikiSubSection heading={'8. How long will you use my personal information for?'}>
                    <Text>
                        8.1. We will only retain your personal information for as long as reasonably necessary to fulfil the purposes we collected it for or subsequently processed, unless: the retention of the information is required or
                        authorised by any legal or regulatory requirements; we reasonably require the information for lawful purposes relating to one of our functions or activities (for example in the event of a complaint or if we
                        reasonably believe there is a prospect of litigation in respect to our relationship with you); the retention of the information is required by a contract entered into between us; or you consent to such retention of
                        information.
                        <br/>
                        <br/>
                        8.2. We may also retain your personal information for a longer period for historical, statistical or research purposes only where we have implemented appropriate safeguards against your information being used for any
                        other purpose (for example, by anonymising the information so that it can no longer be associated with you). To determine the appropriate retention period for personal information, we consider the amount, nature and
                        sensitivity of the personal information, the potential risk of harm from unauthorised use or disclosure of your personal information, the purposes for which we process your personal information and whether we can
                        achieve those purposes through other means, and the applicable legal, regulatory or other requirements.
                        <br/>
                        <br/>
                        8.3 In some circumstances you can ask us to delete your information: see “Your legal rights” below for further information.
                    </Text>
                </WikiSubSection>
                <WikiSubSection heading={'9. Security'}>
                    <Text color={'black'}>
                        9.1. We have appropriate security measures to prevent your personal information from being lost, damaged, destroyed in an unauthorised way or accessed in an unauthorised way. In addition, we limit access to your
                        personal information to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal information on our instructions and they are subject to a
                        duty of confidentiality.
                        <br/>
                        <br/>
                        9.2. We have procedures to deal with any suspected data breach (that is, where we have reasonable grounds to believe that a breach of security has led to the accidental or unlawful destruction, loss, alteration,
                        unauthorised disclosure of, or access to, personal information) and will notify you and any applicable regulator of a breach where we are legally required to do so.
                    </Text>
                </WikiSubSection>
                <WikiSubSection heading={'10. If you fail to provider personal information'}>
                    <Text color={'black'}>
                        10.1. Where we need to collect personal information by law, or under the terms of a contract we have with you, and you fail to provide that information when requested, we may not be able to perform the contract we
                        have or are trying to enter into with you (for example, to provide you with the Services).
                        <br/>
                        <br/>
                        10.2. In this case, we may not be able to proceed with a particular engagement we have or intend to have with you (this may include cancelling a service you have with us), but we will notify you if this is the case
                        at the time.
                    </Text>
                </WikiSubSection>
                <WikiSubSection heading={'11. Your legal rights'}>
                    <Text color={'black'}>
                        11.1 Under certain circumstances, you have the following rights regarding your personal information:
                        <br/>
                        <br/>
                        (1) Notification that your personal information is being collected. When your personal information is collected, you have the right to be informed that your information is collected and where the information is not
                        collected from you, you have the right to be informed of the source that it is collected from.
                        <br/>
                        <br/>
                        (2) Notification of security compromises. Where reasonable grounds exist for us to believe that your personal information has been accessed or acquired by an unauthorised person, you have the right to be notified.
                        <br/>
                        <br/>
                        (3) Institute civil proceedings. You have the right to institute civil proceedings regarding the alleged interference with the protection of your personal information.
                        <br/>
                        <br/>
                        (4) You have the right to request access to, correction and rectification or erasure of your personal information. You may also restrict process, or object to processing in accordance with relevant data protection
                        regulation. If you do request that your information is deleted, all information will be permanently erased, except for information that we are required to keep by law. You may exercise your rights at any time by
                        lodging a request with our Information Officer at privacyqueries@entelect.co.za
                        <br/>
                        <br/>
                        (5) In cases where consent was given, you have the right to withdraw consent at any time. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw
                        your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.
                        <br/>
                        <br/>
                        (6) You have the right to make a complaint at any time to the Information Regulator, the South African regulatory authority for personal information protection issues (http://www.justice.gov.za/inforeg) (Information
                        Regulator) or any applicable regulator in your region. We would however, appreciate the opportunity to deal with your concerns before you approach the Information Regulator so please contact us in the first instance.
                    </Text>
                </WikiSubSection>
                <WikiSubSection heading={'12. Updating your information'}>
                    <Text color={'black'}>
                        12.1. You may update your information in writing at any time by submitting a support request or sending an email to privacyqueries@entelect.co.za or contacting the relevant data protection officer listed above for
                        your region.
                        <br/>
                        <br/>
                        12.2. Please remember that it is your duty to keep information updated so we can correctly provide you with the Services, and you undertake to verify the information you have provided to us from time to time to <br/>
                    </Text>
                </WikiSubSection>
                <WikiSubSection heading={'13. Changes to this policy'}>
                    <Text color={'black'}>
                        This policy may be updated from time to time. You may be required to accept the update Privacy Policy in order to keep using our Services. Alternatively, we may post non-material changes to this Privacy Policy on our
                        Site, with a notice advising of the effective date of the changes.
                    </Text>
                </WikiSubSection>
            </WikiSection>
        </Box>
    )
};
