import {Icon} from '@chakra-ui/icons';
import {Alert, AlertIcon, Box, Flex, Link} from '@chakra-ui/react';
import * as React from 'react';
import {useEffect, useState} from 'react';
import ReactGA from 'react-ga4';
import {Link as RouterLink, Redirect, Route, Switch, useLocation} from 'react-router-dom';
import {useRecoilState, useRecoilValue} from 'recoil';
import {PlayerApi} from '../../api/player.api';
import {cookieAtom} from '../../atoms/cookie.atom';
import {playerAtom} from '../../atoms/player.atom';
import {getSettingAsMessageBoard, SETTING_NAME_OPERATIONAL} from '../../atoms/settings.atom';
import MessageBoardModel from '../../models/message-board.model';
import {IntermediateGlobalLoading} from '../../shared/loading/IntermediateGlobalLoading';
import {CICDFileDownload} from '../cicd-file-download/CICDFileDownload';
import {EntelectChallengeHome} from '../entelect-challenge/EntelectChallengeHome';
import {EntelectChallengeRegister} from '../entelect-challenge/EntelectChallengeRegister';
import {GettingStarted} from '../getting-started/GettingStarted';
import {HackathonHome} from '../hackathon/hackathon-home/HackathonHome';
import {HackathonRegister} from '../hackathon/hackathon-register/HackathonRegister';
import {Home} from '../home/Home';
import {Settings} from '../settings/Settings';
import {ComingSoon} from '../unauthenticated/coming-soon/ComingSoon';
import {UnauthenticatedGame} from '../unauthenticated/game/UnauthenticatedGame';
import {UnauthenticatedUniversity} from '../unauthenticated/hackathon/UnauthenticatedUniversity';
import {UnauthenticatedHome} from '../unauthenticated/home/UnauthenticatedHome';
import {LegacyYearDetail} from '../unauthenticated/legacy/LegacyYearDetail';
import {UnauthenticatedLegacy} from '../unauthenticated/legacy/UnauthenticatedLegacy';
import {UnauthenticatedFooter} from '../unauthenticated/navigation/UnauthenticatedFooter';
import {TeamPage} from '../unauthenticated/team/TeamPage';
import {UnauthenticatedTournaments} from '../unauthenticated/tournaments/UnauthenticatedTournaments';
import {Wiki} from '../Wiki/Wiki';
import {NavigationBar} from './NavigationBar';
import {HackathonFun} from '../fun/HackathonFun';
import {hackathonTournamentAtom} from '../../atoms/hackathon-tournament.atom';
import {HackathonApi} from '../../api/hackathon.api';
import {FiRadio} from "react-icons/fi";

interface INavigation {
}

export const Navigation: React.FunctionComponent<INavigation> = () => {
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [player, setPlayer] = useRecoilState(playerAtom);
    const [, setFunTournament] = useRecoilState(hackathonTournamentAtom);

    const cookiePreferences = useRecoilValue(cookieAtom);

    if (cookiePreferences.nonNecessary) {
        ReactGA.set({ user_id: player.playerId }); // Set user ID in GA4
    }

    const messageBoard: MessageBoardModel | null = useRecoilValue(getSettingAsMessageBoard(SETTING_NAME_OPERATIONAL.MESSAGE_BOARD));

    // Load player only if portal
    useEffect(() => {
        if (location.pathname.startsWith('/portal')) {
            setLoading(true);
            PlayerApi.getPlayer()
                .then(result => {
                    setPlayer(result.data);
                    setLoading(false);
                })
                .catch(reason => reason);
        } else if (location.pathname.startsWith('/fun')) {
            setLoading(true);

            const getTournamentPromise = HackathonApi.getFunTournament();
            const getPlayerPromise = PlayerApi.getPlayer();

            Promise.all([getTournamentPromise, getPlayerPromise])
                .then(([tournamentResponse, playerResponse]) => {
                    setFunTournament(tournamentResponse.data);

                    setPlayer(playerResponse.data);

                    setLoading(false);
                })
                .catch(reason => reason);
        } else {
            setLoading(false);
        }
    }, [setPlayer, setFunTournament, setLoading, location.pathname]);

    const getMessageBoard = (messageBoard: MessageBoardModel) => {
        switch (messageBoard.type) {
            case 'iitpsa': {
                return (
                    <Alert status={messageBoard.status} variant={'subtle'} borderRadius={0} pt={'5em'}>
                        <Icon as={FiRadio} color={'green.500'} fontSize={26} mr={2}/>
                        <Link as={RouterLink} color={'primary.500'} ml={2} to={'/portal'}>
                            {
                                messageBoard.message
                            }
                        </Link>
                    </Alert>
                );
            }
            case 'stream': {
                return (
                    <Alert status={messageBoard.status} variant={'subtle'} borderRadius={0} pt={'5em'}>
                        <Icon as={FiRadio} color={'red.500'} fontSize={26} mr={2}/>
                        {
                            messageBoard.message
                        }
                        <Link as={RouterLink} color={'primary.500'} ml={2} to={'/portal/hackathon/university/stream'}>
                            Click here to view stream.
                        </Link>
                    </Alert>
                );
            }
            case 'message': {
                return (
                    <Alert status={messageBoard.status} variant={'subtle'} borderRadius={0} pt={'5em'}>
                        <AlertIcon/>
                        {
                            messageBoard.message}
                    </Alert>
                );
            }
            default: {
                return (
                    <Alert status={messageBoard.status} variant={'subtle'} borderRadius={0} pt={'5em'}>
                        <AlertIcon/>
                        Default
                    </Alert>
                );
            }
        }
    };

    if (loading) {
        return (
            <IntermediateGlobalLoading/>
        );
    }

    return (
        <Flex direction={'column'} height="100%">
            <Box>
                <NavigationBar player={player}/>
                {
                    messageBoard &&
                    <Box height={'3em'}>
                        {
                            getMessageBoard(messageBoard)
                        }
                    </Box>
                }
            </Box>

            <Box
                pt={messageBoard ? '4em' : '3em'}
                flexGrow={1}
            >
                <Switch>
                    <Route exact path="/portal">
                        <Home/>
                    </Route>
                    <Route exact path="/portal/entelect-challenge/register">
                        <EntelectChallengeRegister/>
                    </Route>
                    <Route path="/portal/entelect-challenge">
                        <EntelectChallengeHome/>
                    </Route>
                    <Route path="/portal/hackathon/:type/register">
                        <HackathonRegister/>
                    </Route>
                    <Route path="/portal/hackathon/:type">
                        <HackathonHome/>
                    </Route>
                    <Route path="/portal/settings">
                        <Settings/>
                    </Route>
                    <Route path={'/wiki'}>
                        <Wiki/>
                    </Route>
                    <Route path="/fun">
                        <HackathonFun/>
                    </Route>

                    {/*Unauthenticated*/}
                    <Route exact path={'/home'}>
                        <UnauthenticatedHome/>
                    </Route>
                    <Route exact path={'/notifications'}>
                        <Redirect to={'/coming-soon'}/>
                    </Route>
                    <Route exact path={'/game'}>
                        <UnauthenticatedGame/>
                    </Route>
                    <Route exact path={'/tournaments'}>
                        <UnauthenticatedTournaments/>
                    </Route>
                    <Route exact path={'/university'}>
                        <UnauthenticatedUniversity/>
                    </Route>
                    <Route exact path={'/team'}>
                        <TeamPage/>
                    </Route>
                    <Route exact path={'/coming-soon'}>
                        <ComingSoon/>
                    </Route>
                    <Route exact path={'/legacy'}>
                        <UnauthenticatedLegacy/>
                    </Route>
                    <Route exact path={'/legacy/:year'}>
                        <LegacyYearDetail/>
                    </Route>
                    <Route exact path={'/getting-started'}>
                        <GettingStarted/>
                    </Route>
                    <Route exact path={'/cicd-download'}>
                        <CICDFileDownload/>
                    </Route>
                </Switch>
            </Box>

            <UnauthenticatedFooter/>
        </Flex>
    );
};
