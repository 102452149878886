import { SquadTypeEnum } from "./squad-type.enum";
import teamImageIndex from "./teamImageIndex";

export type TeamMemberDetails = {
    displayName: string;
    name: string;
    teamSquad: SquadTypeEnum;
    description: string;
    imageFile: any;
}

const teamAssets: TeamMemberDetails[] =
    [
        // Cloud
        {
            displayName: "Neil Foxcroft",
            name: "Neil_Foxcroft",
            teamSquad: SquadTypeEnum.CLOUD,
            description: "desc",
            imageFile: teamImageIndex.Neil_Foxcroft
        },
        {
            displayName: "Francois Volschenk",
            name: "Francois_Volschenk",
            teamSquad: SquadTypeEnum.CLOUD,
            description: "desc",
            imageFile: teamImageIndex.Francois_Volschenk
        },
        {
            displayName: "Husnaa Molvi",
            name: "Husnaa_Movli",
            teamSquad: SquadTypeEnum.CLOUD,
            description: "desc",
            imageFile: teamImageIndex.Husnaa_Molvi
        },

        // Game Engine
        {
            displayName: "Natasha Draper",
            name: "Natasha_Draper",
            teamSquad: SquadTypeEnum.GAME_ENGINE,
            description: "desc",
            imageFile: teamImageIndex.Natasha_Draper
        },
        {
            displayName: "Dewald Swanepoel",
            name: "Dewald_Swanepoel",
            teamSquad: SquadTypeEnum.GAME_ENGINE,
            description: "desc",
            imageFile: teamImageIndex.Dewald_Swanepoel
        },
        {
            displayName: "Kenwood Nyirenda",
            name: "Kenwood_Nyirenda",
            teamSquad: SquadTypeEnum.GAME_ENGINE,
            description: "desc",
            imageFile: teamImageIndex.Kenwood_Nyirenda
        },
        {
            displayName: "Sameer Otham",
            name: "Sameer_Otham",
            teamSquad: SquadTypeEnum.GAME_ENGINE,
            description: "desc",
            imageFile: teamImageIndex.Sameer_Otham
        },
        {
            displayName: "IB van Schalkwyk",
            name: "IB_van_Schalkwyk",
            teamSquad: SquadTypeEnum.GAME_ENGINE,
            description: "desc",
            imageFile: teamImageIndex.IB_van_Schalkwyk
        },
        {
            displayName: "Darshan Dhayaram",
            name: "Darshan_Dhayaram",
            teamSquad: SquadTypeEnum.GAME_ENGINE,
            description: "desc",
            imageFile: teamImageIndex.Darshan_Dhayaram
        },

        // Hackathon
        {
            displayName: "Simeon Boshoff",
            name: "Simeon_Boshoff",
            teamSquad: SquadTypeEnum.HACKATHON,
            description: "desc",
            imageFile: teamImageIndex.Simeon_Boshoff
        },
        {
            displayName: "Douglas van Reeuwyk",
            name: "Douglas_van_Reeuwyk",
            teamSquad: SquadTypeEnum.HACKATHON,
            description: "desc",
            imageFile: teamImageIndex.Douglas_van_Reeuwyk
        },

        // Marketing
        {
            displayName: "Cailin Perrie",
            name: "Cailin_Perrie",
            teamSquad: SquadTypeEnum.MARKETING,
            description: "desc",
            imageFile: teamImageIndex.Cailin_Perrie
        },
        {
            displayName: "Kinesh Pillay",
            name: "Kinesh_Pillay",
            teamSquad: SquadTypeEnum.MARKETING,
            description: "desc",
            imageFile: teamImageIndex.Kinesh_Pillay
        },

        // QA & UI/UX
        {
            displayName: "Gabi Joubert",
            name: "Gabi_Joubert",
            teamSquad: SquadTypeEnum.QA_UIUX,
            description: "desc",
            imageFile: teamImageIndex.Gabi_Joubert
        },
        {
            displayName: "Kumiren Naidoo",
            name: "Kumiren_Naidoo",
            teamSquad: SquadTypeEnum.QA_UIUX,
            description: "desc",
            imageFile: teamImageIndex.Kumiren_Naidoo
        },

        // Project Management & Administration
        {
            displayName: "Bianca McFadyen",
            name: "Bianca_McFadyen",
            teamSquad: SquadTypeEnum.PROJECT_MANAGEMENT,
            description: "desc",
            imageFile: teamImageIndex.Bianca_McFadyen
        },

        {
            displayName: "Lijani van Wyk de Vries",
            name: "Lijani_Van_Wyk_de_Vries",
            teamSquad: SquadTypeEnum.WEBSITE,
            description: "desc",
            imageFile: teamImageIndex.Lijani_Van_Wyk_de_Vries
        },
        {
            displayName: "Sibusiso Dlamini",
            name: "Sibusiso_Dlamini",
            teamSquad: SquadTypeEnum.WEBSITE,
            description: "desc",
            imageFile: teamImageIndex.Sibusiso_Dlamini
        },
        {
            displayName: "Jessica Cordier",
            name: "Jessica_Cordier",
            teamSquad: SquadTypeEnum.WEBSITE,
            description: "desc",
            imageFile: teamImageIndex.Jessica_Cordier
        },
        {
            displayName: "Francois Greeff",
            name: "Francois_Greeff",
            teamSquad: SquadTypeEnum.WEBSITE,
            description: "desc",
            imageFile: teamImageIndex.Francois_Greeff
        }
    ];
export default teamAssets;
