import {DownloadIcon} from "@chakra-ui/icons";
import {Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Code, HStack, Image, ListItem, OrderedList, Select} from "@chakra-ui/react";
import axios from "axios";
import * as React from "react";
import {ChangeEvent, FunctionComponent, useState} from "react";
import {useRecoilState, useRecoilValue} from "recoil";
import {CiCdApi} from "../../../api/ci-cd.api";
import addingCICDFileImage from "../../../assets/images/getting-started/adding_ci_cd_file.png";
import {ciCdLanguageAtom, hasCiCdLanguageAtom} from "../../../atoms/ci-cd-language.atom";
import {enumSelector} from "../../../helpers/typescriptHelper";
import {LanguageOption} from "../../../models/language-option.enum";

interface IProps {
    colorPalette: any
}
export const DownloadCiCdFileAccordionItem: FunctionComponent = (props: IProps) => {
    const hasCiCdLanguageBeenSelected = useRecoilValue(hasCiCdLanguageAtom);
    const [selectedCiCdLanguage, setSelectedCiCdLanguage] = useRecoilState(ciCdLanguageAtom);

    const [isCiCdFileDownloading, setIsCiCdFileDownloading] = useState<boolean>(false);

    const languageOptions = enumSelector(LanguageOption);

    const { mainFontColor, accordionColor, primaryButtonColor, primaryButtonColorHover, codeColorScheme } = props.colorPalette;

    const downloadHeaders = {
        'Content-Disposition': 'attachment; filename="cicd-build.yml"',
        'Content-Type': 'text/plain; charset=utf-8'
    }

    const handleLanguageOptionChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.selectedOptions[0].value;
        setSelectedCiCdLanguage(value);
    }

    const handleDownloadCiCdFile = () => {
        setIsCiCdFileDownloading(true);

        CiCdApi.getCiCdFile(selectedCiCdLanguage)
            .then(result => {

                axios.get(result.data, { headers: downloadHeaders, responseType: 'text' as 'json' })
                    .then(ciCdFileResult => {
                        const blob = new Blob([ciCdFileResult.data], { type: 'text/plain;charset=utf-8' })
                        saveAs(blob, `${selectedCiCdLanguage}.yml`)

                        setIsCiCdFileDownloading(false);
                    })
            })
            .catch(_ => {
                //console.log(error);
                setIsCiCdFileDownloading(false);
            })
    }

    return (
        <AccordionItem>
            <h2>
                <AccordionButton _expanded={{ bg: accordionColor, color: 'white' }}>
                    <Box flex='1' textAlign='left'>
                        Download CI/CD File
                    </Box>
                    <AccordionIcon/>
                </AccordionButton>
            </h2>
            <AccordionPanel pb={4} color={mainFontColor}>
                Almost done, this step is required to enable automatic submission process.
                This file is to tell Github how to build your bot and where it should be submitted to.
                You can re-download the file later, when you want to choose a new language.

                <br/>
                <br/>

                <OrderedList>
                    <ListItem>
                        <HStack spacing='1em'>
                            <Box>
                                Choose the language of the bot you select in the previous step:
                            </Box>
                            <Box>
                                <Select
                                    placeholder='Choose a language'
                                    variant='filled'
                                    value={selectedCiCdLanguage}
                                    onChange={handleLanguageOptionChange}
                                >
                                    {
                                        languageOptions.map((languageOption, index) => {
                                            return <option value={languageOption.value} id={'languageOption_' + index} key={index}>{languageOption.title}</option>
                                        })
                                    }
                                </Select>
                            </Box>
                        </HStack>
                    </ListItem>
                    <ListItem>
                        You can now go ahead and download the CI/CD file for your language:
                        <span>&nbsp;</span>
                        <Button
                            aria-label="Download CI/CD file"
                            size={'sm'}
                            leftIcon={<DownloadIcon/>}
                            backgroundColor={primaryButtonColor}
                            colorScheme={'navigation'}
                            color={'whiteAlpha.900'}
                            isDisabled={!hasCiCdLanguageBeenSelected}
                            isLoading={isCiCdFileDownloading}
                            loadingText='Downloading'
                            _hover={{
                                backgroundColor: primaryButtonColorHover
                            }}
                            onClick={handleDownloadCiCdFile}
                        >
                            Download
                        </Button>
                    </ListItem>
                    <ListItem>
                        Copy the downloaded file to the .github/workflows folder of your bots folder.
                    </ListItem>
                    <ListItem>
                        Delete the <Code colorScheme={codeColorScheme}>.gitkeep</Code> file in that folder.
                    </ListItem>
                </OrderedList>

                <br/>

                You now have all the files required for you to automatically submit your bot to the Entelect Challenge servers via Github.

                <br/>

                The next step, you are going to commit and push your code to Github.

                <Accordion
                    pt={3}
                    allowToggle
                >
                    <AccordionItem>
                        <h2>
                            <AccordionButton
                                _expanded={{ bg: accordionColor, color: 'white' }}
                            >
                                <Box flex='1' textAlign='left'>
                                    Click here for help with adding the CI/CD file
                                </Box>
                                <AccordionIcon/>
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <Image src={addingCICDFileImage} fallbackSrc={addingCICDFileImage} alt='Adding CI/CD file'/>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>

            </AccordionPanel>
        </AccordionItem>
    );
}
